import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {BaseDataGrid} from "../../../../shared/utils/base-data-grid";
import DataSource from "devextreme/data/data_source";
import {PaymentOperationModel} from "../../../../shared/models/payment-operation.model";
import {forEach} from "lodash";
import {PaymentIncidentsService} from "../../../payment/services/payment.incidents.service";
import {NotificationService} from "../../../../shared/services/notifications.service";
import {TranslocoService} from "@ngneat/transloco";

@Component({
  selector: 'app-incident-group-solved-modal',
  templateUrl: './incident-group-solved-modal.component.html',
  styleUrls: ['./incident-group-solved-modal.component.css']
})
export class IncidentGroupSolvedModalComponent extends BaseDataGrid implements OnInit, AfterViewInit {
    pageSize: number = 10;
    isLoading: boolean = true;
    isSaving: boolean = false;
    title: string;
    userId: string;
    userMessage: string;
    faSpinner = faSpinner
    selectedRowKeys: any[] = [];
    paymentOperations: PaymentOperationModel[] = [];
    createdAt: string = '';
    incidentId: string = '';

    constructor(
        private dialogRef: MatDialogRef<IncidentGroupSolvedModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private paymentIncidentsService: PaymentIncidentsService,
        private notificationService: NotificationService,
        private translocoService: TranslocoService
    ) {
        super();
        this.title = data.title;
        this.userId = data.userId;
        this.createdAt = data.createdAt;
        this.incidentId = data.incidentId;
    }

    ngAfterViewInit(): void {
        this.paymentIncidentsService.setDataGrid(this.dataGrid);
    }

    ngOnInit(): void {
        this.dataSource = new DataSource({
            key: "id",
            load: (loadOptions: any) => {
                const filter = {'userId==*': this.userId, 'createdAt>=': this.createdAt, 'paymentStatus==*': 'Completed'}
                loadOptions.skip = loadOptions.skip || 0;
                loadOptions.take = loadOptions.take || this.pageSize;
                return this.paymentIncidentsService.getAll(loadOptions, this.isRefresh, ['initialTotalAmount'], filter);
            }
        });
    }

    onSelectionChanged(event: any) {
        this.selectedRowKeys = event.selectedRowsData;
        console.log(this.selectedRowKeys);
    }

    submit(): void {
        forEach(this.selectedRowKeys, (operation) => {
            const operationData : PaymentOperationModel = {
                id: operation.id,
                paymentReference: operation.paymentClientTxnReference,
                amount: Math.round(operation.initialTotalAmount * 100),
                paymentCreatedAt: operation.createdAt,
            }
            this.paymentOperations.push(operationData);
        });
        this.dialogRef.close({paymentOperations: this.paymentOperations, userMessage: this.userMessage});
    }

    close(): void {
        this.dialogRef.close(null);
    }

    onToolbarPreparing(e): void {
        e.toolbarOptions.items.unshift(
            {
                location: 'before',
                template: 'titleTemplate',
                locateInMenu: 'never',
            },
            {
                location: 'after',
                template: 'refresh',
                locateInMenu: 'auto',
            },
            {
                location: 'after',
                template: 'clear',
                locateInMenu: 'auto',
            },
            {
                location: 'after',
                template: 'clear-all',
                locateInMenu: 'auto',
            },
        );
    }

    onContentReady(event: any) {
        if (!event.component.getDataSource()?.items() || event.component.getDataSource()?.items().length === 0) {
            this.isLoading = false;
            this.notificationService.error(this.translocoService.translate('NO_PAYMENT_OPERATIONS_FOUND_BY_INCIDENT'));
            this.dialogRef.close(null);
        }
    }
}
