<dx-data-grid [dataSource]="dataSource" [remoteOperations]="true" (onToolbarPreparing)="onToolbarPreparing($event)">

    <div *dxTemplate="let data of 'titleTemplate'">
        <span class="font-bold text-2xl text-accent">{{ 'COMMON.PAYMENT_CARDS' | transloco }}</span>
    </div>
    <div *dxTemplate="let data of 'refresh'">
        <button mat-icon-button class="button-toolbar-custom-class" (click)="refresh()"
                matTooltip="{{'COMMON.REFRESH' | transloco}}">
            <mat-icon>sync</mat-icon>
        </button>
    </div>
    <div *dxTemplate="let data of 'clear'">
        <button mat-icon-button class="button-toolbar-custom-class" (click)="clear()"
                matTooltip="{{'COMMON.CLEAN_FILTER' | transloco}}">
            <mat-icon>filter_alt_off</mat-icon>
        </button>
    </div>
    <div *dxTemplate="let data of 'clear-all'">
        <button mat-icon-button class="button-toolbar-custom-class" (click)="clearFilterSorting()"
                matTooltip="{{'COMMON.CLEAN_FILTER_SORTING' | transloco}}">
            <mat-icon>restore_page</mat-icon>
        </button>
    </div>

    <dxi-column dataField="id" [width]="110" dataType="string" cellTemplate="cellTemplateIds"
                [filterOperations]="['=']"></dxi-column>
    <div *dxTemplate="let cell of 'cellTemplateIds'">
        <app-id-table-template [id]="cell.text"></app-id-table-template>
    </div>

    <dxi-column *ngIf="permission == 'GetPaymentCardsQuery'" dataField="userId" caption="{{'COMMON.USER_ID' | transloco}}" [width]="110" dataType="string" cellTemplate="cellTemplateIds"
                [filterOperations]="['=']"></dxi-column>
    <div *dxTemplate="let cell of 'cellTemplateIds'">
        <app-id-table-template [id]="cell.text"></app-id-table-template>
    </div>
    <dxi-column *ngIf="permission == 'GetPaymentCardsQuery'" dataField="userEmail" caption="{{'USER_EMAIL' | transloco}}" dataType="string"></dxi-column>

    <dxi-column dataField="lastFour" caption="{{'CARD.LAST_FOUR' | transloco}}" alignment="center" dataType="string"></dxi-column>

    <dxi-column dataField="expirationMonth" caption="{{'CARD.EXPIRATION_MONTH' | transloco}}" alignment="center" dataType="number"></dxi-column>
    <dxi-column dataField="expirationYear" caption="{{'CARD.EXPIRATION_YEAR' | transloco}}" alignment="center" dataType="number"></dxi-column>

    <dxi-column dataField="type" caption="{{'COMMON.TYPE' | transloco}}" dataType="string"></dxi-column>
    <dxi-column dataField="scheme" caption="{{'COMMON.SCHEME' | transloco}}" dataType="string"></dxi-column>
    <dxi-column dataField="bank" caption="{{'CARD.BANK' | transloco}}" dataType="string"></dxi-column>

    <dxi-column dataField="firstName" caption="{{'COMMON.FIRST_NAME' | transloco}}" dataType="string"></dxi-column>
    <dxi-column dataField="lastName" caption="{{'COMMON.LAST_NAME' | transloco}}" dataType="string"></dxi-column>

    <dxi-column dataField="addressLine" caption="{{'CARD.ADDRESS_LINE' | transloco}}" dataType="string"></dxi-column>
    <dxi-column dataField="state" caption="{{'COMMON.STATE' | transloco}}" dataType="string"></dxi-column>
    <dxi-column dataField="city" caption="{{'COMMON.CITY' | transloco}}" dataType="string"></dxi-column>

    <dxi-column dataField="phoneNumber" caption="{{'COMMON.PHONE_NUMBER' | transloco}}" dataType="string"></dxi-column>
    <dxi-column dataField="fullPhone" caption="{{'CARD.FULL_PHONE' | transloco}}" dataType="string"></dxi-column>

    <dxi-column dataField="createdAt" sortOrder="desc" caption="{{'COMMON.CREATED' | transloco}}" dataType="datetime"
                cellTemplate="dateCreatedTemplate">
    </dxi-column>
    <div *dxTemplate="let cell of 'dateCreatedTemplate'">
        {{ cell.data.createdAt | date: 'd MMM yyyy H:mm:ss a' }}
    </div>
    <dxi-column dataField="updatedAt" caption="{{'COMMON.UPDATED' | transloco}}" dataType="datetime"
                cellTemplate="dateUpdatedTemplate">
    </dxi-column>
    <div *dxTemplate="let cell of 'dateUpdatedTemplate'">
        {{ cell.data.updatedAt | date: 'd MMM yyyy H:mm:ss a' }}
    </div>

    <dxi-column dataField="blockPayments" caption="{{'CARD.BLOCK_PAYMENTS' | transloco}}" dataType="boolean"></dxi-column>
    <dxi-column dataField="hideForUser" caption="{{'COMMON.HIDE_FOR_USER' | transloco}}" dataType="boolean"></dxi-column>

    <dxo-paging [pageSize]="pageSize"></dxo-paging>
    <dxo-pager visible="true" [showPageSizeSelector]="true" [allowedPageSizes]="allowedPageSizes"
               [showNavigationButtons]="true" [showInfo]="true"
               infoText="{{'COMMON.PAGE' | transloco}} {0} {{'COMMON.OF' | transloco}} {1} ({2} {{'COMMON.RESULTS' | transloco}})">
    </dxo-pager>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-state-storing [enabled]="true"></dxo-state-storing>
    <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
</dx-data-grid>
