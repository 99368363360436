import {inject, Injectable} from "@angular/core";
import { DxDataGridComponent } from "devextreme-angular";
import * as moment from 'moment';
import { KeyStore } from "../../core/auth/auth.service";
import { HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { map } from "rxjs/operators";
import {NotificationService} from "../services/notifications.service";
import {tap} from "rxjs";

const convertToAppTimezone = (date: Date | string | number) => {
    return moment.utc(date).toISOString();
};

@Injectable({
    providedIn: "root",
})
export class BaseDataGridService {
    url: string;
    resource: string;
    dataGrid: DxDataGridComponent;
    _httpClient: any;
    currentPage: number;
    pageSize: number;
    notificationService = inject(NotificationService);

    constructor(url: string, resource: string, httpClient: any) {
        this.url = url;
        this.resource = resource;
        this._httpClient = httpClient;
    }

    getAllTableObservable(filters, sorts, page, pageSize, params, url :string | null = null, isTrim: boolean = false, returnFilter: boolean = false) {
        const httpParams = this.getFilterParams(filters, sorts, page, pageSize, params);
        return this._httpClient.get(url ? url : this.url, {params: httpParams})
            .pipe(
                map((response: any) => {
                    const lastPageSize = this.pageSize;
                    this.currentPage = response.data.currentPage;
                    this.pageSize = response.data.pageSize;
                    if (returnFilter) {
                        return {
                            data: response.data && response.data.items ? isTrim ? response.data.items.slice(0, lastPageSize) : response.data.items.slice(0, this.pageSize) : [],
                            totalCount: response.data && response.data.totalCount ? response.data.totalCount : 0,
                            filters: filters
                        };
                    } else {
                        return {
                            data: response.data && response.data.items ? isTrim ? response.data.items.slice(0, lastPageSize) : response.data.items.slice(0, this.pageSize) : [],
                            totalCount: response.data && response.data.totalCount ? response.data.totalCount : 0
                        };
                    }
                })
            );
    }

    getAll(loadOptions: any, isRefresh: boolean, mapFields: string[] = [], filter: any = null,
           params: { key: string, value: any } | { key: string, value: any }[] | null = null, url :string | null = null, returnFilters: boolean = false): Promise<any> {
        let sorts = '';
        if (loadOptions.sort) {
            loadOptions.sort.forEach((element) => {
                sorts = element.desc ? '-' + element.selector : element.selector;
            });
        }
        let filters;
        if (filter) {
            const filtersAux = loadOptions.filter
                ? this.buildNewFilters(loadOptions.filter, mapFields)
                : {};
            filters = Object.assign(filtersAux, filter);
        } else {
            filters = loadOptions.filter
                ? this.buildNewFilters(loadOptions.filter, mapFields)
                : {};
        }
        if (mapFields.length) {
            return new Promise((resolve, reject) => {
                // const page = (loadOptions.skip / loadOptions.take) + 1;

                let page: number = (loadOptions.skip / loadOptions.take) + 1;
                let pageSize;
                if (Number.isInteger(page)) {
                    pageSize = loadOptions.take;
                    this.getAllTableObservable(filters, sorts, page, pageSize, params, url, false, returnFilters)
                        .pipe(
                            map((data: any) => {
                                data.data.forEach(it => {
                                    mapFields.forEach(key => {
                                        if (it[key]) {
                                            it[key] = Number((it[key] / 100).toFixed(2));
                                        }
                                    });
                                });
                                return data;
                            })
                        )
                        .subscribe(
                            (data) => resolve(data),
                            (error: any) => {
                                this.handlerError(error);
                                return resolve({data: [], totalCount: 0});
                            }
                    );
                } else {
                    if (loadOptions && loadOptions.skip && loadOptions.take) {
                        page = Math.floor(loadOptions.skip / loadOptions.take) + 1;
                        pageSize = loadOptions.take + this.pageSize;
                    } else {
                        page = 1;
                        pageSize = this.pageSize;
                    }
                    this.getAllTableObservable(filters, sorts, page, pageSize, params, url, false, returnFilters)
                        .pipe(
                            map((data: any) => {
                                data.data.forEach(it => {
                                    mapFields.forEach(key => {
                                        if (it[key]) {
                                            it[key] = Number((it[key] / 100).toFixed(2));
                                        }
                                    });
                                });
                                return data;
                            })
                        ).subscribe(
                            (data) => resolve(data),
                            (error: any) => {
                                this.handlerError(error);
                                return resolve({data: [], totalCount: 0});
                            }
                    );
                }
            });
        } else {
            return new Promise((resolve, reject) => {
                let page: number = (loadOptions.skip / loadOptions.take) + 1;
                let pageSize;
                if (Number.isInteger(page)) {
                    this.getAllTableObservable(filters, sorts, page, loadOptions.take, params, url, false, returnFilters).subscribe(
                        (data) => resolve(data),
                        (error: any) => {
                            this.handlerError(error);
                            return resolve({data: [], totalCount: 0});
                        }
                    );
                } else {
                    if (loadOptions && loadOptions.skip && loadOptions.take) {
                        page = Math.floor(loadOptions.skip / loadOptions.take) + 1;
                        pageSize = loadOptions.take + this.pageSize;
                    } else {
                        page = 1;
                        pageSize = this.pageSize;
                    }
                    page = Math.floor(loadOptions.skip / loadOptions.take) + 1;
                    this.getAllTableObservable(filters, sorts, page, pageSize, params, url , true, returnFilters).subscribe(
                        (data) => resolve(data),
                        (error: any) => {
                            this.handlerError(error);
                            return resolve({data: [], totalCount: 0});
                        }
                    );
                }
                // this.getAllTableObservable(filters, sorts, page, loadOptions.take, params).subscribe((data) => resolve(data));
            });
        }
    }

    handlerError(error: any) {
        this.notificationService.error(error && error.error && error.error.message ? error.error.message : error.message);
    }

    getFilterParams(filters, sorts, page, pageSize, params): HttpParams {
        const httpParamsArray = Object.keys(filters);
        let httpFilterString = '';
        httpParamsArray.forEach((key: string, index: number) => {
            if (index === 0) {
                httpFilterString += key + filters[key];
            } else {
                httpFilterString += ',' + key + filters[key];
            }
        });
        if (httpFilterString != '') {
            if (params) {
                if (params instanceof Array) {
                    const obj = {};
                    obj['filters'] = httpFilterString;
                    obj['sorts'] = sorts;
                    obj['page'] = page;
                    obj['pageSize'] = pageSize;
                    params.forEach(it => {
                        obj[it.key] = it.value;
                    });
                    return new HttpParams({fromObject: obj});
                } else {
                    return new HttpParams()
                        .set('filters', httpFilterString)
                        .set('sorts', sorts)
                        .set('page', page)
                        .set('pageSize', pageSize)
                        .set(params.key, params.value);
                }
            } else {
                return new HttpParams()
                    .set('filters', httpFilterString)
                    .set('sorts', sorts)
                    .set('page', page)
                    .set('pageSize', pageSize);
            }
        } else {
            if (params) {
                if (params instanceof Array) {
                    const obj = {};
                    obj['sorts'] = sorts;
                    obj['page'] = page;
                    obj['pageSize'] = pageSize;
                    params.forEach(it => {
                        obj[it.key] = it.value;
                    });
                    return new HttpParams({fromObject: obj});
                } else {
                    return new HttpParams()
                        .set('sorts', sorts)
                        .set('page', page)
                        .set('pageSize', pageSize)
                        .set(params.key, params.value);
                }
            } else {
                return new HttpParams()
                    .set('sorts', sorts)
                    .set('page', page)
                    .set('pageSize', pageSize);
            }
        }
    }

    // isFilterDifferentArray(newFilterArray: any, lastWhereArray: any): boolean {
    //     let result = false;
    //     newFilterArray.forEach(it => {
    //         let diferent = true;
    //         lastWhereArray.map(element => {
    //             if (!this.isFilterDifferent(it, element)) {
    //                 diferent = false;
    //             }
    //         });
    //         if (diferent) {
    //             result = true;
    //         }
    //     });
    //     return result;
    // }
    //
    // isFilterDifferent(newFilter: any, lastWhere: any): boolean {
    //     let result = true;
    //     if (newFilter === undefined && lastWhere === undefined) {
    //         result = false;
    //     } else if (newFilter === undefined || lastWhere === undefined) {
    //         result = true;
    //     } else {
    //         let isEqual = true;
    //         Object.keys(newFilter).map((key) => {
    //             if ((key === 'OR' || key === 'AND') && lastWhere[key]) {
    //                 isEqual = !this.isFilterDifferentArray(newFilter[key], lastWhere[key]);
    //             } else if (!lastWhere[key] || newFilter[key] !== lastWhere[key]) {
    //                 isEqual = false;
    //             }
    //         });
    //         if (isEqual) {
    //             Object.keys(lastWhere).map((key) => {
    //                 if ((key === 'OR' || key === 'AND') && newFilter[key]) {
    //                     isEqual = !this.isFilterDifferentArray(lastWhere[key], newFilter[key]);
    //                 } else if (!newFilter[key] || newFilter[key] !== lastWhere[key]) {
    //                     isEqual = false;
    //                 }
    //             });
    //         }
    //         if (isEqual) {
    //             result = false;
    //         }
    //     }
    //
    //     return result;
    // }

    // buildAfter(position: number, totalCount: number): string {
    //     let after: any = {
    //         __totalCount: totalCount,
    //         __position: position - 1,
    //     };
    //     after = JSON.stringify(after);
    //     after = btoa(after);
    //     return after;
    // }

    getOperatorEventDate(operator: string = 'string'): string {
        const operators = {
            "<": "To",
            "<=": "To",
            ">=": "From",
            ">": "From",
            "=": "",
            "": "_not",
        };
        return operators[operator];
    }

    // convertObjFilter(filters: any) {
    //     let filtersBuilded = {};
    //     if (filters[0] == 'client' || filters[0] == 'correlationId' || filters[0] == 'performerId' || filters[0] == 'scope' || filters[0] == 'targetId') {
    //         filtersBuilded[filters[0]] = filters[2];
    //     } else if (filters[0] == 'performerEmail' || filters[0] == 'description') {
    //         const numberExpression = /^[0-9]+$/;
    //         if (filters[0] == 'performerEmail' && filters[2].match(numberExpression)) {
    //             filtersBuilded['performerId'] = Number(filters[2]);
    //         } else {
    //             filtersBuilded[filters[0]+ 'Contains'] = filters[2];
    //         }
    //     } else {
    //         if (filters[0] && Array.isArray(filters[0])) {
    //             filtersBuilded = Object.assign(filtersBuilded, this.convertObjFilter(filters[0]));
    //             filtersBuilded = Object.assign(filtersBuilded, this.convertObjFilter(filters[2]));
    //         } else {
    //             filtersBuilded[filters[0]+ this.getOperatorEventDate(filters[1])] = filters[2];
    //         }
    //     }
    //     return filtersBuilded;
    // }
    //
    // buildEventFilters(filters: any) {
    //     let filtersBuilded = {};
    //
    //     if (filters) {
    //         if (this.isFilter(filters)) {
    //             filtersBuilded = this.convertObjFilter(filters);
    //         } else {
    //             Object.keys(filters).forEach((f, index) => {
    //                 if (this.isFilter(filters[f])) {
    //                     filtersBuilded = Object.assign(filtersBuilded, this.convertObjFilter(filters[f]));
    //                 }
    //             });
    //         }
    //     }
    //     return filtersBuilded;
    // }

    buildNewFilters(filters: any, mapFields: string[] = []) {
        let filtersBuilded = {};

        if (filters) {
            if (this.isFilter(filters)) {
                const filterBuilded = this.convertDxFilter(filters, mapFields);
                filtersBuilded = filterBuilded;
            } else {
                Object.keys(filters).forEach((f, index) => {
                    if (this.isFilter(filters[f])) {
                        const filterBuilded = this.convertDxFilter(filters[f], mapFields);
                        filtersBuilded = Object.assign(
                            filtersBuilded,
                            filterBuilded
                        );
                    }
                });
            }
        }
        return filtersBuilded;
    }

    isFilterSelect(filter: any): boolean {
        return (filter instanceof Object && filter.length > 2 && filter != 'or' && filter != 'and' && filter != '!' && !(filter[0] instanceof Object));
    }

    buildSelectionFilters(filters: any, operator: string | null = null): any[] {
        let filtersBuilt = [];
        if (filters) {
            if (this.isFilterSelect(filters)) {
                filtersBuilt = this.convertDxFilterSelection(filters, operator);
            } else {
                filters.forEach((filter: any, index) => {
                    if (filter != 'or' && filter != 'and' && filter != '!' && (!filters[index-1] || (filters[index-1] && filters[index-1] != '!' ))) {
                        const filterBuilt = this.buildSelectionFilters(filter, operator);
                        filtersBuilt = [...filtersBuilt, ...filterBuilt];
                        // filtersBuilt = Object.assign(
                        //     filtersBuilt,
                        //     filterBuilt
                        // );
                    } else if (filter == '!') {
                        const filterBuilt = this.buildSelectionFilters(filters[index + 1], '<>');
                        filtersBuilt = [...filtersBuilt, ...filterBuilt];
                        // filtersBuilt = Object.assign(
                        //     filtersBuilt,
                        //     filterBuilt
                        // );
                    }
                });
            }
        }
        return filtersBuilt;
    }

    // buildFilters(filters: any, dataGrid: DxDataGridComponent = null) {
    //     if (!this.dataGrid) {
    //         this.dataGrid = dataGrid;
    //     }
    //     let filtersBuilded = {};
    //
    //     if (filters) {
    //         if (this.isFilter(filters)) {
    //             const filterBuilded = this.convertDxFilter(filters);
    //             filtersBuilded = filterBuilded;
    //         } else {
    //             Object.keys(filters).forEach((f, index) => {
    //                 if (this.isFilter(filters[f])) {
    //                     const filterBuilded = this.convertDxFilter(filters[f]);
    //                     if (filterBuilded["OR"]) {
    //                         if (filtersBuilded["AND"]) {
    //                             filtersBuilded["AND"].push({ "OR": filterBuilded["OR"] });
    //                         } else {
    //                             filtersBuilded["AND"] = [{ "OR": filterBuilded["OR"] }];
    //                         }
    //                     } else {
    //                         filtersBuilded = Object.assign(
    //                             filtersBuilded,
    //                             filterBuilded
    //                         );
    //                     }
    //                 }
    //             });
    //         }
    //     }
    //     return filtersBuilded;
    // }

    convertDxFilterSelection(filter: any, operator: string | null = null): any[] {
        // let resultFilter = {};
        // const dataType = this.getFilterDataType(filter);
        // if (dataType === "date" || dataType === "datetime") {
        //     resultFilter = this.convertDateFilter(filter);
        // } else if (dataType === "number") {
        //     const dataField = this.getFilterDatadataField(filter);
        //     // if (mapFields.length && dataField == 'amount') {
        //     //     resultFilter = this.convertNumberFilter(filter, true);
        //     // } else {
        //     resultFilter = this.convertNumberFilter(filter);
        //     // }
        // } else if (dataType === "string") {
        //     resultFilter = this.convertStringFilter(filter);
        // } else {
        //     resultFilter[filter[0] + this.getOperator(filter[1], dataType, operator)] =
        //         filter[2];
        // }
        // return resultFilter;

        let resultFilter = {};
        let resultArray = [];
        const dataType = this.getFilterDataType(filter);
        resultFilter[filter[0] + this.getOperator(filter[1], dataType, operator)] = filter[2];
        resultArray.push(resultFilter);
        return resultArray;
    }

    convertDxFilter(filter: any, mapFields: string[] = []): any {
        let resultFilter = {};
        const dataType = this.getFilterDataType(filter);
        if (dataType === "date" || dataType === "datetime") {
            resultFilter = this.convertDateFilter(filter);
        } else if (dataType === "number") {
            const dataField = this.getFilterDatadataField(filter);
            // if (mapFields.length && dataField == 'amount') {
            //     resultFilter = this.convertNumberFilter(filter, true);
            // } else {
            resultFilter = this.convertNumberFilter(filter, mapFields);
            // }
        } else if (dataType === "string") {
            resultFilter = this.convertStringFilter(filter);
        } else {
            resultFilter[filter[0] + this.getOperator(filter[1], dataType)] =
                filter[2];
        }
        return resultFilter;
    }

    // convertStringFilterByPhoneNumber(filter: any) {
    //     let resultFilterString = {};
    //     const operator = this.getOperator(filter[1], "string");
    //     let filterString = '';
    //
    //     for (let i = 0; i < filter[2].length; i++) {
    //         if (filter[2].charAt(i) !== ' ' && filter[2].charAt(i) !== '(' && filter[2].charAt(i) !== ')' && filter[2].charAt(i) !== '-') {
    //             filterString = filterString + filter[2].charAt(i);
    //         }
    //     }
    //
    //     resultFilterString[filter[0] + operator] = filterString;
    //     return resultFilterString;
    // }

    convertStringFilter(filter: any) {
        let resultFilterString = {};
        const searchText = filter[2].trim();
        const operator = this.getOperator(filter[1], "string");
        resultFilterString[filter[0] + operator] = searchText;

        return resultFilterString;
    }

    // convertToCaseSensitive(search: string, flag: boolean) {
    //     let index = 0;
    //     for (let i = 0; i < search.length; i++) {
    //         if (search.charAt(i) !== ' ') {
    //             index = i;
    //             break;
    //         }
    //     }
    //     const text = search;
    //     const result = text?.substr(index + 1);
    //     return flag ? text?.charAt(index)?.toUpperCase() + result : text?.charAt(index)?.toLowerCase() + result;
    // }

    // possibleCombinations(arrayWords: string[], countWords: number): string[] {
    //     const arrayResult = [];
    //
    //     const arrayUpperCaseWords = this.converterUpperCaseWords(arrayWords);
    //     const arrayLowerCaseWords = this.converterLowerCaseWords(arrayWords);
    //
    //     arrayResult.push(this.converterStringbyArray(arrayUpperCaseWords));
    //     arrayUpperCaseWords.forEach((item, index) => {
    //         const copyArrayWords = [...arrayUpperCaseWords];
    //         const lowerCaseWords = this.convertToCaseSensitive(item, false);
    //         copyArrayWords.splice(index, 1, lowerCaseWords);
    //         arrayResult.push(this.converterStringbyArray(copyArrayWords));
    //     });
    //
    //     arrayResult.push(this.converterStringbyArray(arrayLowerCaseWords));
    //     if (countWords > 2) {
    //         arrayLowerCaseWords.forEach((item, index) => {
    //             const copyArrayWords = [...arrayLowerCaseWords];
    //             const upperCaseWords = this.convertToCaseSensitive(item, true);
    //             copyArrayWords.splice(index, 1, upperCaseWords);
    //             arrayResult.push(this.converterStringbyArray(copyArrayWords));
    //         });
    //     }
    //
    //     return arrayResult;
    // }

    // converterStringbyArray(arrayWords: string[]): string {
    //     let result = '';
    //     arrayWords.map((it, index) => {
    //         if (index === 0) {
    //             result = it;
    //         } else {
    //             result = result + ' ' + it;
    //         }
    //     });
    //     return result;
    // }

    // converterUpperCaseWords(arrayWords: string[]): string[] {
    //     const arrayResult = [];
    //     arrayWords.map(it => {
    //         arrayResult.push(this.convertToCaseSensitive(it, true));
    //     });
    //     return arrayResult;
    // }
    //
    // converterLowerCaseWords(arrayWords: string[]): string[] {
    //     const arrayResult = [];
    //     arrayWords.map(it => {
    //         arrayResult.push(this.convertToCaseSensitive(it, false));
    //     });
    //     return arrayResult;
    // }

    convertNumberFilter(filter: any, mapFields: string[] = []) {
        let resultFilterNumber = {};
        if (typeof filter[0] === "string") {
            if (mapFields.length && mapFields.some(it => it === filter[0])) {
                resultFilterNumber[
                    filter[0] + this.getOperator(filter[1], "number")
                ] = Math.round(Number(filter[2].toFixed(2)) * 100);
            } else {
                resultFilterNumber[
                    filter[0] + this.getOperator(filter[1], "number")
                ] = filter[2];
            }
        } else {
            if (filter[1] === "and" && mapFields.length && mapFields.some(it => it === filter[0][0])) {
                resultFilterNumber[
                    filter[0][0] + this.getOperator(filter[0][1], "number")
                ] = Math.round(Number(filter[0][2].toFixed(2)) * 100);
                resultFilterNumber[
                    filter[2][0] + this.getOperator(filter[2][1], "number")
                ] = Math.round(Number(filter[2][2].toFixed(2)) * 100);
            } else if (filter[1] === "and") {
                resultFilterNumber[
                    filter[0][0] + this.getOperator(filter[0][1], "number")
                ] = filter[0][2];
                resultFilterNumber[
                    filter[2][0] + this.getOperator(filter[2][1], "number")
                ] = filter[2][2];
            }
        }

        return resultFilterNumber;
    }

    convertDateFilter(filter: any) {
        let resultFilterDate = {};
        if (typeof filter[0] === "string") {
            resultFilterDate[
                filter[0] + this.getOperator(filter[1], "date")
            ] = convertToAppTimezone(filter[2]);
        } else {
            if (filter[1] === "and") {
                resultFilterDate[
                    filter[0][0] + this.getOperator(filter[0][1], "date")
                ] = convertToAppTimezone(filter[0][2]);
                resultFilterDate[
                    filter[2][0] + this.getOperator(filter[2][1], "date")
                ] = convertToAppTimezone(filter[2][2]);
            } else {
                const initial = {};
                const end = {};
                initial[
                    filter[0][0] + this.getOperator(filter[0][1], "date")
                ] = convertToAppTimezone(filter[0][2]);
                end[
                    filter[2][0] + this.getOperator(filter[2][1], "date")
                ] = convertToAppTimezone(filter[2][2]);
                resultFilterDate["OR"] = [initial, end];
            }
        }
        return resultFilterDate;
    }

    getFilterDatadataField(filter) {
        if (!this.isFilter(filter)) {
            return null;
        }
        // @ts-ignore
        return this.dataGrid.columns[filter.columnIndex].dataField;
    }

    getFilterDataType(filter) {
        if (!this.isFilter(filter)) {
            return null;
        }
        // @ts-ignore
        return this.dataGrid.columns[filter.columnIndex].dataType;
    }

    getOperator(dxOperator: string, dataType: string = '', operator: string | null = null): string {
        const operators = {
            boolean: {
                "=": "==",
            },
            number: {
                "=": "==",
                "<>": "!=",
                "<": "<",
                ">": ">",
                "<=": "<=",
                ">=": ">=",
            },
            date: {
                "<": "<",
                "<=": "<=",
                ">=": ">=",
                ">": ">",
                "=": "==",
                "": "!=",
            },
            string: {
                contains: "@=*",
                notcontains: "!@=*",
                startswith: "_=*",
                endswith: "_-=*",
                "=": "==*",
                "<>": "!=*",
            },
            // boolean: {
            //     "=": "",
            // },
            // number: {
            //     "=": "",
            //     "<>": "_not",
            //     "<": "_lt",
            //     ">": "_gt",
            //     "<=": "_lte",
            //     ">=": "_gte",
            // },
            // date: {
            //     "<": "_lt",
            //     "<=": "_lte",
            //     ">=": "_gte",
            //     ">": "_gt",
            //     "=": "",
            //     "": "_not",
            // },
            // string: {
            //     contains: "_contains",
            //     notcontains: "_not_contains",
            //     startswith: "_starts_with",
            //     endswith: "_ends_with",
            //     "=": "",
            //     "<>": "_not",
            // },
        };
        if (dataType && dataType != '') {
            return operators[dataType][operator ?? dxOperator];
        } else {
            return operators['string'][operator ?? dxOperator];
        }
    }

    isFilter(object: any): boolean {
        return object.hasOwnProperty("filterValue");
    }

    setDataGrid(dataGrid: DxDataGridComponent, savePageIndex: boolean = true) {
        // const userId = JSON.parse(localStorage.getItem("userData"))?.id;
        const json = localStorage.getItem(KeyStore.CURRENT_USER) ?? '';
        const userId = JSON.parse(json)?.id;

        this.dataGrid = dataGrid;
        this.dataGrid.columnResizingMode = "widget";
        this.dataGrid.allowColumnResizing = true;
        this.dataGrid.columnAutoWidth = true;
        this.dataGrid.columnMinWidth = 50;
        this.dataGrid.rowAlternationEnabled = true;
        this.dataGrid.showRowLines = true;
        this.dataGrid.allowColumnReordering = true;

        this.dataGrid.stateStoring.savingTimeout = 0;
        // this.dataGrid.scrolling.scrollByContent = true;
        // this.dataGrid.scrolling.scrollByThumb = true;

        if (savePageIndex) {
            this.dataGrid.stateStoring.type = "localStorage";
        } else {
            this.dataGrid.stateStoring.type = "custom";
        }
        // this.dataGrid.stateStoring.type = "localStorage";

        this.dataGrid.stateStoring.storageKey = userId ? `${this.resource + userId}Storage` : `${this.resource}Storage`;
        // this.dataGrid.stateStoring.storageKey = "storage";

        this.dataGrid.filterRow.showAllText = "";

        this.dataGrid.editing.mode = "popup";

        if (!savePageIndex) {
            this.dataGrid.stateStoring.customSave = (state) => {
                const stateCopy = Object.assign({}, state);
                delete stateCopy.pageIndex;
                localStorage.setItem(this.dataGrid.stateStoring.storageKey, JSON.stringify(stateCopy));
            };
            this.dataGrid.stateStoring.customLoad = () => {
                let state = localStorage.getItem(this.dataGrid.stateStoring.storageKey);
                if (state) {
                    state = JSON.parse(state);
                    if (state['pageIndex']) {
                        delete state['pageIndex'];
                    }
                }
                return state;
            }
        }
    }

    clearSelection(): void {
        this.dataGrid.instance?.deselectAll();
        this.dataGrid.instance?.clearSelection();
    }
}
