import {Component, EventEmitter, forwardRef, OnDestroy, OnInit, Output} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALUE_ACCESSOR,
    ValidationErrors
} from "@angular/forms";
import {debounceTime, Observable, of, Subject, Subscription, switchMap} from "rxjs";
import {UserService} from "../../../modules/user/services/user.service";
import {PromocodeService} from "../../../modules/promocodes/services/promocode.service";
import {user} from "../../../mock-api/common/user/data";
import {takeUntil} from "rxjs/operators";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

@Component({
    selector: 'app-user-autocomplete',
    templateUrl: './user-autocomplete.component.html',
    styleUrls: ['./user-autocomplete.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => UserAutocompleteComponent),
            multi: true
        }
    ]
})
export class UserAutocompleteComponent implements OnInit, OnDestroy, ControlValueAccessor {

    previousValue: string = ''

    userForm: FormGroup = new FormGroup<any>({
        userId: new FormControl<string>(null),
    });
    userFormSubscriptions: Subscription;
    isLoadingUser: boolean = false;
    isGetDefaultUsers: boolean = false;
    filteredUsers: any[] = [];
    filteredDefaultUsers: any[] = [];
    isLoading: boolean;
    private destroy$ = new Subject<void>();
    @Output() userSelected = new EventEmitter<string>();

    constructor(private userService: UserService) {
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
        this.userFormSubscriptions.unsubscribe();
    }

    ngOnInit(): void {
        this.searchUsers();
        if (!this.isLoadingUser) {
            this.isLoading = false;
        }
    }

    searchUsers() {
        this.userFormSubscriptions = this.userForm.get('userId').valueChanges
            .pipe(debounceTime(400))
            .subscribe(value => {
                if (this.previousValue === value) {
                    return;
                }

                this.previousValue = value;

                if (value || (value == '' && !this.isGetDefaultUsers)) {
                    this.isLoadingUser = true;
                    if (value instanceof Object) {
                        this.filteredUsers = [];
                        this.isLoadingUser = false;
                    } else {
                        this.userService.getAutocompleteByEmail(value.displayName ? value.displayName : value).subscribe((usersResponse: {
                            data: {
                                id: string,
                                email: string
                            }[]
                        }) => {
                            this.filteredUsers = usersResponse.data;
                            if (value == '' && !this.isGetDefaultUsers) {
                                this.filteredDefaultUsers = usersResponse.data;
                                this.isGetDefaultUsers = true;
                            }
                            this.isLoadingUser = false;
                        });
                    }
                } else if (value == '' && this.isGetDefaultUsers) {
                    this.filteredUsers = [...this.filteredDefaultUsers];
                }
            });
    }

    displayUserFn(user: {id: string, email: string}) {
        if (user) {
            return user.email;
        }
    }

    IsInvalidUserForm() {
        return this.userForm.invalid || !(this.userForm.get('userId').value instanceof Object);
    }

    protected readonly faSpinner = faSpinner;

    lockUser() {
        const user = this.userForm.value.userId;
        this.userSelected.emit(user.id);
        this.userForm.reset();
    }

    // ControlValueAccessor methods
    writeValue(value: any): void {
        this.userForm.get('userId').setValue(value);
    }

    registerOnChange(fn: any): void {
        this.userForm.get('userId').valueChanges.subscribe(fn);
    }

    registerOnTouched(fn: any): void {
        // Implement if needed
    }

    setDisabledState?(isDisabled: boolean): void {
        if (isDisabled) {
            this.userForm.disable();
        } else {
            this.userForm.enable();
        }
    }
}

export function userAutocompleteFormValidator(): (control: AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {

        // Is invalid is control.value.email is not an email or control.value.id is not a string
        if (control.value instanceof Object) {

            console.log(control.value);

            if (!control.value.email || !control.value.id) {
                return { invalidUserForm: true };
            }

            console.log(control.value.email);

            // Add a regex for emil in control.value.email
            const emailRegex = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$');
            if (!emailRegex.test(control.value.email)) {
                return {invalidUserForm: true};
            }
            console.log(control.value.id);
        } else {
            return {invalidUserForm: true};
        }
    };
}
