export function convertKeysToLowerCase(obj: any): any {
    if (typeof obj !== 'object' || obj === null) {
        return obj;
    }

    if (Array.isArray(obj)) {
        return obj.map(convertKeysToLowerCase);
    }

    return Object.keys(obj).reduce((acc, key) => {
        const lowerCaseKey = key.charAt(0).toLowerCase() + key.slice(1);
        acc[lowerCaseKey] = convertKeysToLowerCase(obj[key]);
        return acc;
    }, {} as any);
}
