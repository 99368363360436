import {Component, OnDestroy, OnInit} from '@angular/core';
import {NotificationTrayComponent} from "../notification-tray/notification-tray.component";
import {MatDialog} from "@angular/material/dialog";
import {InternalNotificationService} from "../../services/internal-notification.service";
import {Subscription} from "rxjs";
import {PusherService} from "../../services/pusher.service";
import {AuthService} from "../../../core/auth/auth.service";
import {User} from "../../../core/user/user.types";
import {convertKeysToLowerCase} from "../../utils/dto-utils";
import {InternalNotificationDto} from "../../interfaces/internalNotificationDto.interface";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
    selector: 'notification-button',
    templateUrl: './notification-button.component.html',
    styleUrls: ['./notification-button.component.scss']
})
export class NotificationButtonComponent implements OnInit, OnDestroy {

    unreadNotifications: number = 0; // Example value, replace with actual logic
    currentUserId: string | null = null;
    private onRemoveFromCountEvent: Subscription;


    constructor(
        private authService: AuthService,
        private dialog: MatDialog,
        private service: InternalNotificationService,
        private pusherService: PusherService,
        private snackBar: MatSnackBar
    ) {
        this.service.getUnreadCount().subscribe((data) => {
            this.unreadNotifications = data.data.unreadCount;
        });

        this.service.addEvent.subscribe((notification: InternalNotificationDto) => {
            this.unreadNotifications++;
            this.snackBar.open(notification.title, '', {
                duration: 5000,
                horizontalPosition: 'center',
                verticalPosition: 'top'
            });
        });
    }

    ngOnInit() {
        this.onRemoveFromCountEvent = this.service.readOrDeleteEvent.subscribe((count: number) => {
            this.unreadNotifications -= count;
        });

        const currentUser = this.authService.keyCurrentUser;
        if (currentUser) {
            console.log(currentUser)
            this.currentUserId = currentUser.id;
            this.pusherService.bindEvent(currentUser.id, (data: any) => {
                const newNotification = convertKeysToLowerCase(data) as InternalNotificationDto;
                this.service.addEventSubject.next(newNotification);
            });
        }
    }

    ngOnDestroy() {
        this.onRemoveFromCountEvent.unsubscribe();
        if (this.currentUserId) {
            this.pusherService.unbindEvent(this.currentUserId);
        }
    }

    openNotificationTray(): void {
        this.dialog.open(NotificationTrayComponent, {
            disableClose: false,
            position: {right: '0px'},
            width: '384px',
        });
    }
}
