<mat-toolbar color="primary" mat-dialog-title fxLayout fxLayoutAlign="center center">
    <span>{{ title | transloco | uppercase }}</span>
</mat-toolbar>

<mat-dialog-content class="p-0 m-0" *ngIf="!is_loading">
    <div class="max-width-class flex flex-col gap-2">
        <div class="flex flex-col">

            <mat-card class="" *ngFor="let item of items">
                <mat-card-content class="">
                    <ngx-json-viewer [json]="item" [expanded]="false">
                    </ngx-json-viewer>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-content class="p-0 m-0 flex justify-center items-center w-full h-full" *ngIf="is_loading">
    <mat-spinner class="w-9 h-9 mt-5 mb-5"></mat-spinner>
</mat-dialog-content>

<mat-dialog-actions class="items-center justify-center">
    <button mat-raised-button mat-dialog-close="close">{{'COMMON.CANCEL' | transloco}}</button>
</mat-dialog-actions>
