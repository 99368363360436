import { Inject, Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { delay, Observable, of, switchMap, throwError, BehaviorSubject, empty, take } from 'rxjs';
import { FUSE_MOCK_API_DEFAULT_DELAY } from '@fuse/lib/mock-api/mock-api.constants';
import { FuseMockApiService } from '@fuse/lib/mock-api/mock-api.service';
import { AuthService, Token} from '../../../app/core/auth/auth.service';
import { catchError, filter } from "rxjs/operators";
import { Router } from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class FuseMockApiInterceptor implements HttpInterceptor {
    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    excludedResources = [
        'api/apps/',
        'api/common/',
        'api/dashboards/',
        'api/pages/',
        'api/ui/',
        's3.amazonaws.com',
        'api/v1/management/identity/account/auth/refresh',
        '/api/v1/management/identity/account/auth/login/redeemcode',
    ];
    excludedXKAppResources = [
        'api/v1/discovery/enum',
        'api/v1/Orders/StoreShippingAddresses/DeliveryCountries',
        'api/v1/Payment/BillingAddress/BillingCountries',
        'api/v1/Orders/StoreShippingAddresses/DeliveryStates',
        'api/v1/discovery/enum',
        'api/v1/management/identity/account/auth/refresh',
        '/api/v1/management/identity/account/auth/login/redeemcode',
        '/api/v1/identity/account/getaccesscode',
        '/api/v1/management/Orders/ExternalConciliation',
        '/api/v1/management/Payment/External/Orders/RefundStatus',
        '/api/v1/management/Payment/External/Orders/RefundPayment',
        '/api/v1/Management/Inventory/productCategories/AddImage',
        '/api/v1/Zones'
    ];
    constructor(
        @Inject(FUSE_MOCK_API_DEFAULT_DELAY) private _defaultDelay: number,
        private _fuseMockApiService: FuseMockApiService,
        private _authService: AuthService,
        private _router: Router
    ) {}

    private addToken(request: HttpRequest<any>, token: string) {
        return request.clone({
            setHeaders: {
                'Authorization': `Bearer ${token}`
            }
        });
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const excluded = this.excludedResources.some((re) => request.url.includes(re));
        if (excluded) {
            const {
                handler,
                urlParams
            } = this._fuseMockApiService.findHandler(request.method.toUpperCase(), request.url);

            // Pass through if the request handler does not exist
            if ( !handler )
            {
                return next.handle(request);
            }
            // Set the intercepted request on the handler
            handler.request = request;
            // Set the url params on the handler
            handler.urlParams = urlParams;
            // Subscribe to the response function observable
            return handler.response.pipe(
                delay(handler.delay ?? this._defaultDelay ?? 0),
                switchMap((response) => {
                    // If there is no response data,
                    // throw an error response
                    if ( !response )
                    {
                        response = new HttpErrorResponse({
                            error     : 'NOT FOUND',
                            status    : 404,
                            statusText: 'NOT FOUND'
                        });

                        return throwError(response);
                    }
                    // Parse the response data
                    const data = {
                        status: response[0],
                        body  : response[1]
                    };
                    // If the status code is in between 200 and 300,
                    // return a success response
                    if ( data.status >= 200 && data.status < 300 )
                    {
                        response = new HttpResponse({
                            body      : data.body,
                            status    : data.status,
                            statusText: 'OK'
                        });

                        return of(response);
                    }
                    // For other status codes,
                    // throw an error response
                    response = new HttpErrorResponse({
                        error     : data.body.error,
                        status    : data.status,
                        statusText: 'ERROR'
                    });
                    return throwError(response);
                }));
        }
        if (this._authService.keyAccessToken && !excluded ) {
            request = this.addToken(request, this._authService.keyAccessToken);
        }

        const excludedXKApp = this.excludedXKAppResources.some((re) => request.url.includes(re));
        if (!excludedXKApp) {
            request = this.setPanelHeaders(request);
        }

        return next.handle(request).pipe(catchError(error => {
            return throwError(error);
        }));
    }

    private setPanelHeaders(request: HttpRequest<any>) {
        return request.clone({
            setHeaders: {
                'X-K-App': '4'
            }
        });
    }

}
