<div [ngClass]="{'id-table-class': isTable}" *ngIf="id">
    <!-- <div matTooltip="{{id}}">{{ getSplitId() }}</div> -->
<!--    [href]="navigate"-->
    <button mat-icon-button [color]="buttonColor" *ngIf="isCanNavigate && isValidId" (click)="navigateRute($event)"
            matTooltip="{{ 'COMMON.DETAIL' | transloco }}" [matTooltipPosition]="'above'">
        <mat-icon>visibility</mat-icon>
    </button>

    <button mat-icon-button [color]="buttonColor" (click)="callServiceToCopy()" matTooltip="{{ 'COMMON.COPY' | transloco }}"
            [matTooltipPosition]="'above'">
        <mat-icon class="s-20">content_copy</mat-icon>
    </button>
</div>
