<mat-toolbar color="primary" mat-dialog-title fxLayout fxLayoutAlign="center center">
    <span>{{ title | transloco | uppercase }}</span>
</mat-toolbar>

<mat-dialog-content class="p-4 m-0 min-h-60 block">
    <dx-data-grid
        [dataSource]="dataSource"
        [remoteOperations]="true"
        (onToolbarPreparing)="onToolbarPreparing($event)"
        (onSelectionChanged)="onSelectionChanged($event)"
        [showBorders] = "true"
        (onContentReady)="onContentReady($event)"
        [syncLookupFilterValues]="false">

        <dxo-filter-row [visible]="false" ></dxo-filter-row>

        <div *dxTemplate="let data of 'titleTemplate'">
            <span class="font-bold text-accent">{{ 'PAYMENT_OPERATIONS' | transloco }}</span>
        </div>

        <div *dxTemplate="let data of 'refresh'">
            <button mat-icon-button class="button-toolbar-custom-class" (click)="refresh()"
                    matTooltip="{{'COMMON.REFRESH' | transloco}}">
                <mat-icon>sync</mat-icon>
            </button>
        </div>
        <div *dxTemplate="let data of 'clear'">
            <button mat-icon-button class="button-toolbar-custom-class" (click)="clear()"
                    matTooltip="{{'COMMON.CLEAN_FILTER' | transloco}}">
                <mat-icon>filter_alt_off</mat-icon>
            </button>
        </div>
        <div *dxTemplate="let data of 'clear-all'">
            <button mat-icon-button class="button-toolbar-custom-class" (click)="clearFilterSorting()"
                    matTooltip="{{'COMMON.CLEAN_FILTER_SORTING' | transloco}}">
                <mat-icon>restore_page</mat-icon>
            </button>
        </div>


        <dxo-selection
            mode="multiple"
            showCheckBoxesMode="always"
            allowSelectAll="false">
        </dxo-selection>
        <dxi-column dataField="paymentClientTxnReference" [width]="120" alignment="center" caption="{{'REFERENCE' | transloco}}"
                    dataType="string" allowEditing="false">
        </dxi-column>
        <dxi-column dataField="initialTotalAmount" [width]="60" alignment="center" caption="{{'COMMON.AMOUNT' | transloco}}"
                    dataType="number" allowEditing="false">
        </dxi-column>

        <dxi-column dataField="createdAt" sortOrder="desc" [width]="120" alignment="center" caption="{{'COMMON.CREATED' | transloco}}"
                    dataType="datetime" allowEditing="false"
                    cellTemplate="dateCreatedTemplate">
        </dxi-column>
        <div *dxTemplate="let cell of 'dateCreatedTemplate'">
            {{ cell.data.createdAt | date: 'd MMM yyyy H:mm:ss a' }}
        </div>

        <dxo-paging [pageSize]="pageSize"></dxo-paging>
        <dxo-pager visible="true" [showPageSizeSelector]="true" [allowedPageSizes]="allowedPageSizes"
                   [showNavigationButtons]="true" [showInfo]="true"
                   infoText="{{'COMMON.PAGE' | transloco}} {0} {{'COMMON.OF' | transloco}} {1} ({2} {{'COMMON.RESULTS' | transloco}})">
        </dxo-pager>
        <dxo-filter-row [visible]="true" [applyFilter]="'auto'"></dxo-filter-row>
        <!--            <dxo-header-filter [visible]="true"></dxo-header-filter>-->
        <dxo-state-storing [enabled]="true"></dxo-state-storing>
        <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
        <dxo-editing [enabled]="false"></dxo-editing>
    </dx-data-grid>

    <div class="w-full">
            <mat-form-field class="w-full">
                <mat-label>{{ 'NOTE' | transloco }}</mat-label>
                <input matInput [(ngModel)]="userMessage" class="border border-accent-50">
            </mat-form-field>
    </div>
</mat-dialog-content>


<mat-dialog-actions class="flex justify-center">
    <button
        mat-raised-button
        color="primary"
        (click)="submit()">
        <span>{{ 'SUBMIT' | transloco }}</span>
        <fa-icon
            *ngIf="isSaving"
            class="ml-2 animate-spin"
            [icon]="faSpinner">
        </fa-icon>
    </button>
    <button
        mat-raised-button
        mat-dialog-close>
        {{ 'COMMON.CANCEL' | transloco }}
    </button>
</mat-dialog-actions>
