<!--<app-internal-notifications [allNotifications]="false"></app-internal-notifications>-->
<div class="relative min-h-screen max-h-screen p-4 rounded-l flex flex-col">
    <div class="flex flex-row justify-between items-center p-4 mr-[20%] border-b border-gray-200">
        <h3 class="text-lg font-semibold text-gray-800">{{ 'NOTIFICATIONS' | transloco }}</h3>
        <button
            (click)="markAllRead()"
            class="flex text-sm text-blue-#089CE3 hover:text-blue-#18396C outline-none"
        >
            {{ 'MARK_ALL_AS_READ' | transloco }}

            <svg *ngIf="loading" class="animate-spin h-5 w-5 text-blue-#089CE3" xmlns="http://www.w3.org/2000/svg"
                 fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M12 4v4m0 8v4m8-8h-4m-8 0H4m16.24-4.24l-2.83 2.83M6.34 6.34l2.83 2.83m8.48 8.48l-2.83-2.83M6.34 17.66l2.83-2.83"/>
            </svg>

        </button>
        <button (click)="closeTray()" class="absolute top-3 right-3 text-xl text-gray-500 hover:text-gray-700 focus:outline-none">
            <fa-icon [icon]="faTimes"></fa-icon>
        </button>
    </div>

    <div *ngFor="let notification of notifications" class="flex flex-col gap-2 space-y-4">
        <app-notification-card
            [notification]="notification"
            [showFullDetails]="false"
            (toggleRead)="readAndClose($event)"
            (delete)="removeFromList($event)"
        >
        </app-notification-card>
    </div>

    <div *ngIf="notifications.length === 0" class="text-center text-gray-500 pt-4">
        {{ 'NO_NOTIFICATIONS' | transloco }}
    </div>

    <button *ngIf="notifications.length > 0" class="relative font-bold flex justify-center items-center text-yellow-#FF6D03 bg-white border-2 border-yellow-#FF6D03 rounded-full font-Lato-Regular text-md w-full h-10 mt-7 py-2" (click)="navigateToFull()">
        {{ 'SEE_MORE' | transloco }}
    </button>
</div>
