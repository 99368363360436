// src/app/modules/shared/notifications/internal-notifications/notification-actions.ts
import {faCaravan, faExternalLink, faShoppingCart, faTowerBroadcast} from "@fortawesome/free-solid-svg-icons";
import {InternalNotificationDto} from "../../interfaces/internalNotificationDto.interface";
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";

export interface NotificationAction {
    icon: IconDefinition;
    label: string;
    color: string;
    isLink: boolean;
    handler: (notification: InternalNotificationDto) => string;
}

export const notificationActions: { [key: string]: NotificationAction[] } = {
    Shipment: [
        {
            label: 'ORDER',
            icon: faShoppingCart,
            color: 'orange-button',
            isLink: true,
            handler: (notification) => {
                const orderId = notification.metadata.OrderId;
                return `/marketplace/orders/${orderId}/info`;
            }
        },
    ],
    Order: [
        {
            label: 'ORDER',
            icon: faShoppingCart,
            color: 'orange-button',
            isLink: true,
            handler: (notification) => {
                const orderId = notification.metadata.OrderId;
                return `/marketplace/orders/${orderId}/info`;
            }
        },
    ],
    CubacelTopUp: [
        {
            label: 'CUBACEL_TOP_UPS',
            icon: faTowerBroadcast,
            color: 'orange-button',
            isLink: true,
            handler: (notification) => {
                return `/cubacel/recharges`;
            }
        }
    ],
    Payment: [],
    Default: []
};
