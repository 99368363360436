import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {CardLimitService} from "../../services/card-limit-service";
import {NotificationService} from "../../../../shared/services/notifications.service";

@Component({
    selector: 'app-card-limit-change-dialog',
    templateUrl: './card-limit-change-dialog.component.html'
})
export class CardLimitChangeDialogComponent implements OnInit {
    onlyOne: boolean;
    userId: string;
    limit: number;
    paymentClientId: string;
    isSaving: boolean = false;
    form: FormGroup = new FormGroup<any>({
        limit: new FormControl<number|null>(null),
        description: new FormControl<string|null>(null)
    });

    constructor(
        private dialogRef: MatDialogRef<CardLimitChangeDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private cardLimitService: CardLimitService,
        private _notificationService: NotificationService
    ) {
        this.onlyOne = data.onlyOne;
        this.userId = data.userId ?? '';
        this.limit = data.limit ?? 1;
        this.paymentClientId = data.paymentClientId ?? '';
        if (this.onlyOne) {
            this.form.get('limit').patchValue(this.limit);
            this.form.get('limit').setValidators([Validators.required, Validators.min(1)]);
            this.form.get('description').setValidators([Validators.required]);
        }
    }

    ngOnInit(): void {}

    disableForm() {
        this.form.get('limit').disable();
        this.form.get('description').disable();
    }

    enableForm() {
        this.form.get('limit').enable();
        this.form.get('description').enable();
    }

    change() {
        this.isSaving = true;
        this.disableForm();
        if (this.onlyOne) {
            const formData = this.form.getRawValue();
            const data = {
                userId: this.userId,
                paymentClientId: this.paymentClientId,
                limit: formData.limit,
                description: formData.description
            };
            this.cardLimitService.changeLimit(data).subscribe( response => {
                this._notificationService.success('CARD.LIMIT_CHANGED_SUCCESSFULLY');
                this.enableForm();
                this.isSaving = false;
                this.dialogRef.close(response);
            }, error => {
                this._notificationService.error(error.message);
                this.isSaving = false;
                this.enableForm();
            });
        } else {
            // Generic query
        }
    }

    close(): void {
        this.dialogRef.close(null);
    }
}
