<mat-toolbar color="primary" mat-dialog-title>
    <span>{{title | transloco | uppercase}}</span>
</mat-toolbar>

<mat-dialog-content [formGroup]="form" class="flex flex-col gap-1 pt-5 min-w-100" [ngClass]="{'opacity-50 pointer-events-none': isLoading}">
    <div *ngIf="message !== ''" [ngClass]="{'text-center': textCenterTitle}">
        <div *ngIf="messageBlack" class="weight-bold-class text-lg text-center">{{ message | transloco }}</div>
        <div *ngIf="!messageBlack" class="text-lg text-center">{{ message | transloco }}</div>
    </div>

    <div *ngIf="subMessage != ''" class="sub-message-class text-lg text-center">{{ subMessage | transloco }}</div>

    <mat-form-field
        class="w-full mt-2"
        [subscriptSizing]="'dynamic'">
        <mat-label>{{ label | transloco }}</mat-label>
        <textarea
            matInput
            formControlName='note'
            [spellcheck]="false"
            [rows]="4"
            [placeholder]="placeholder | transloco"
            cdkTextareaAutosize></textarea>
        <mat-error *ngIf="form.get('note').hasError('required')">
            {{ 'COMMON.REQUIRED_FIELD' | transloco }}
        </mat-error>
    </mat-form-field>

</mat-dialog-content>

<mat-dialog-actions class="flex flex-row items-center justify-center">
    <button mat-raised-button (click)="onSubmit()" color="primary" [ngClass]="{'pr-2': isLoading}" *ngIf="!isWarnBotton" [disabled]="isLoading">
        <div class="flex items-center">
            <div>{{confirmAction | transloco}}</div>
            <fa-icon *ngIf="isLoading"
                     class="relative flex justify-center items-center animate-spin rounded-full w-9 h-9 text-xl text-white"
                     [icon]="faSpinner">
            </fa-icon>
        </div>
    </button>
    <button mat-raised-button (click)="onSubmit()" color="warn" *ngIf="isWarnBotton" [disabled]="isLoading" [ngClass]="{'pr-2': isLoading}">
        <div class="flex items-center">
            <div>{{confirmAction | transloco}}</div>
            <fa-icon *ngIf="isLoading"
                     class="relative flex justify-center items-center animate-spin  bg-yellow-#FF6D03 rounded-full w-9 h-9 text-xl text-white"
                     [icon]="faSpinner">
            </fa-icon>
        </div>
    </button>
    <button [disabled]="isLoading" mat-raised-button mat-dialog-close="close">{{cancelAction | transloco}}</button>
</mat-dialog-actions>
