<div (click)="onPrimaryAction()" class="flex justify-between items-start p-4 border-b last:border-b-2 overflow-y-auto">
  <div class="flex items-start">
    <div class="flex-shrink-0 mr-4">
      <fa-icon [icon]="faBell" [ngClass]="{'text-gray-400': notification.readAt, 'text-orange-500': !notification.readAt}" class="h-6 w-6"></fa-icon>
    </div>
    <div class="flex-grow">
      <h3 class="text-sm font-medium">{{ notification.title }}</h3>
      <p [ngClass]="{'line-clamp-2': !showFullDetails}" class="mt-1 text-sm text-gray-500">{{ notification.message }}</p>
      <p class="mt-2 text-xs text-gray-400">{{ notification.createdAt | timeAgo }}</p>
    </div>
  </div>
  <div class="flex-shrink-0 ml-4">
    <button (click)="onDelete($event)" class="p-1 text-red-500 hover:text-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 flex items-center">
      <fa-icon [icon]="trash" class="h-5 w-5 mr-1"></fa-icon>
      <span class="text-sm">{{'DELETE' | transloco}}</span>
    </button>
    <div *ngIf="notification.typeName">
      <ng-container *ngFor="let action of notificationActions[notification.typeName]">
        <ng-container *ngIf="action.isLink; else buttonTemplate">
          <a [routerLink]="action.handler(notification)" (click)="$event.stopPropagation()" [ngClass]="action.color" class="p-1 focus:outline-none focus:ring-2 focus:ring-offset-2 flex items-center mt-2">
            <fa-icon class="h-5 w-5 mr-1" [icon]="action.icon"></fa-icon>
            <span class="text-sm">{{ action.label | transloco }}</span>
          </a>
        </ng-container>
        <ng-template #buttonTemplate>
          <button (click)="action.handler(notification); $event.stopPropagation();" [ngClass]="action.color" class="p-1 focus:outline-none focus:ring-2 focus:ring-offset-2 flex items-center mt-2">
            <fa-icon class="h-5 w-5 mr-1" [icon]="action.icon"></fa-icon>
            <span class="text-sm">{{ action.label | transloco }}</span>
          </button>
        </ng-template>
      </ng-container>
    </div>

  </div>
</div>
