<mat-toolbar color="primary" mat-dialog-title fxLayout fxLayoutAlign="center center">
    <span>{{ 'RESET_PASSWORD' | transloco | uppercase }}</span>
</mat-toolbar>

<mat-dialog-content class="p-2 pt-0 m-0 min-h-26 flex justify-center flex-col">
    <div class="fuse-splash-screen" *ngIf="isLoading">
        <div class="spinner">
            <div class="bounce1 bg-orange-600"></div>
            <div class="bounce2 bg-orange-600"></div>
            <div class="bounce3 bg-orange-600"></div>
        </div>
    </div>

    <h6 class="pl-6 pt-5" *ngIf="!isLoading">{{'RESET_PASSWORD_MESSAGE' | transloco}}</h6>

    <div [formGroup]="form" class="flex flex-1 flex-col gap-2" *ngIf="!isLoading">
        <div class="flex flex-col px-6 pt-4">

            <mat-form-field [ngClass]="{'mb-4': hasPasswordError()}" class="fuse-mat-bold w-full fuse-mat-dense">
                <mat-label>{{ 'COMMON.PASSWORD' | transloco }}</mat-label>
                <input matInput required formControlName="password"
                       [type]='fieldPasswordType' name="password"
                       [formControl]="passwordControl"
                       class="w-full py-2 focus:outline-none border border-gray-300 rounded
             font-Lato-Light ">
                <fa-icon (click)="showHidePassword()"
                         class="absolute right-2 py-2 rounded-full w-7 cursor-pointer" style="color: #1e293b"
                         [icon]="iconPasswordType">
                </fa-icon>
                <mat-error *ngIf="passwordControl.hasError('required') && passwordControl.touched">{{ 'COMMON.REQUIRED_FIELD' | transloco }}</mat-error>
                <mat-error *ngIf="passwordControl.hasError('minlength') && !passwordControl.hasError('required') && passwordControl.touched">{{'MINIMUM_CHAR' | transloco}}</mat-error>
                <mat-error *ngIf="passwordControl.hasError('pattern') && !passwordControl.hasError('minlength') && !passwordControl.hasError('required') && passwordControl.touched">{{'WRONG_PASSWORD' | transloco}}</mat-error>
            </mat-form-field>

            <mat-form-field [ngClass]="{'mb-6': hasConfirmPasswordError()}" class="fuse-mat-bold w-full fuse-mat-dense">
                <mat-label>{{ 'COMMON.CONFIRM_PASSWORD' | transloco }}</mat-label>
                <input matInput required formControlName="confirmPassword"
                       [type]='fieldConfirmPasswordType' name="confirmPassword"
                       [formControl]="confirmPasswordControl"
                       class="w-full py-2 focus:outline-none border border-gray-300 rounded
             font-Lato-Light ">
                <fa-icon (click)="showHideConfirmPassword()"
                         class="absolute right-2 py-2 rounded-full w-7 cursor-pointer" style="color: #1e293b"
                         [icon]="iconConfirmPasswordType">
                </fa-icon>
                <mat-error *ngIf="confirmPasswordControl.hasError('required') && confirmPasswordControl.touched">{{ 'COMMON.REQUIRED_FIELD' | transloco }}</mat-error>
                <mat-error *ngIf="confirmPasswordControl.hasError('minlength') && !confirmPasswordControl.hasError('required') && confirmPasswordControl.touched">{{'MINIMUM_CHAR' | transloco}}</mat-error>
                <mat-error *ngIf="confirmPasswordControl.hasError('pattern') && !confirmPasswordControl.hasError('minlength') && !confirmPasswordControl.hasError('required') && confirmPasswordControl.touched">{{'WRONG_PASSWORD' | transloco}}</mat-error>
            </mat-form-field>

            <div *ngIf="form.controls['password'].value !== form.controls['confirmPassword'].value
                  && form.controls['password'].touched && form.controls['confirmPassword'].touched"
                 class="text-start pb-2">
                <p class="relative font-Lato-Regular text-sm text-red-600 h-3">
          <span>
              {{'MUST_MATCH' | transloco}}
          </span>
                </p>
            </div>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions class="items-center justify-center" *ngIf="!isLoading">
    <button mat-raised-button [disabled]="form.invalid" [color]="'primary'" (click)="submit()" [ngClass]="{'pr-2': isSaving}">
        <div class="flex flex-row items-center">
            <span>{{ 'RESET' | transloco}}</span>
            <fa-icon *ngIf="isSaving"
                     class="relative flex justify-center items-center animate-spin rounded-full w-9 h-9 text-xl text-white"
                     [icon]="faSpinner">
            </fa-icon>
        </div>
    </button>
    <button mat-raised-button mat-dialog-close="close" [disabled]="isSaving">{{'COMMON.CANCEL' | transloco}}</button>
</mat-dialog-actions>


