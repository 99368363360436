import {Pipe, PipeTransform} from '@angular/core';
import {TranslocoService} from "@ngneat/transloco";

@Pipe({
    name: 'timeAgo'
})
export class TimeAgoPipe implements PipeTransform {

    constructor(private translate: TranslocoService) {
    }

    transform(value: any): string {
        if (value) {
            const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
            if (seconds < 29) // less than 30 seconds ago will show as 'JUST NOW'
                return this.translate.translate('JUST_NOW');
            const intervals: { [key: string]: number } = {
                'YEAR': 31536000,
                'MONTH': 2592000,
                'WEEK': 604800,
                'DAY': 86400,
                'HOUR': 3600,
                'MINUTE': 60,
                'SECOND': 1
            };
            let counter: number;
            for (const i in intervals) {
                counter = Math.floor(seconds / intervals[i]);
                if (counter > 0) {
                    if (counter === 1) {
                        return `${counter} ${this.translate.translate(i)}`; // singular (1 DAY AGO)
                    } else if (i === 'MONTH') {
                        return `${counter} ${this.translate.translate('MONTHS')}`; // plural (2 MONTHS AGO)
                    } else {
                        return `${counter} ${this.translate.translate(i)}s`; // plural (2 DAYS AGO)
                    }
                }
            }
        }
        return value;
    }
}
