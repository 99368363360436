import {Injectable} from '@angular/core';
import Pusher, {Channel} from 'pusher-js';
import {InternalNotificationService} from "./internal-notification.service";
import {environment} from "../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class PusherService {
    private pusher: Pusher;
    private channel: Channel;

    constructor(private notificationService: InternalNotificationService) {
        this.pusher = new Pusher(environment.pusher_app_id, {
            cluster: 'mt1'
        });

        this.channel = this.pusher.subscribe('notifications');
    }

    public bindEvent(eventName: string, callback: (data: any) => void): void {
        this.channel.bind(eventName, callback);
    }

    public unbindEvent(eventName: string, callback?: (data: any) => void): void {
        this.channel.unbind(eventName, callback);
    }
}
