<mat-toolbar color="primary" mat-dialog-title fxLayout fxLayoutAlign="center center">
    <span>{{ title | transloco | uppercase }}</span>
</mat-toolbar>

<mat-dialog-content class="p-0 m-0">
    <div class="max-width-class flex flex-col gap-2">
        <div class="flex flex-col">

            <mat-card class="">
                <mat-card-content class="">
                    <ngx-json-viewer *ngIf="!isViewModified" [json]="dataJson" [expanded]="true">
                    </ngx-json-viewer>
                    <json-editor *ngIf="isViewModified" [options]="editorOptions" [data]="dataForm" (change)="onJsonChange($event)"></json-editor>
                </mat-card-content>
            </mat-card>

        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions class="items-center justify-center">
    <button mat-raised-button [color]="'primary'" (click)="save()" *ngIf="isViewModified">
        <div class="flex flex-row items-center">
            <span>{{ 'COMMON.SAVE' | transloco}}</span>
        </div>
    </button>
    <button mat-raised-button mat-dialog-close="close">{{'COMMON.CANCEL' | transloco}}</button>
</mat-dialog-actions>
