import { Component, OnInit, Inject } from "@angular/core";
import {
    MatDialogRef,
    MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NotificationService} from "../../services/notifications.service";
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: "app-confirm-note-dialog-component",
    templateUrl: "./confirm-note-dialog.component.html",
    styleUrls: ["./confirm-note-dialog.component.scss"],
})
export class ConfirmNoteDialogComponent implements OnInit {
    isLoading: boolean = false;
    title: string;
    action: any;
    message: string;
    messageBlack: boolean;
    subMessage: string;
    confirmAction: string;
    cancelAction: string;
    notificationSuccess: string;
    isWarnBotton: boolean;
    multipleAction = false;
    textCenterTitle = false;
    notificationFailMultiple: string;
    label: string;
    placeholder: string;
    id: string;
    noteId: string;
    editText: string;
    isEditNote: boolean = false;
    storeOrderId: string;

    form: FormGroup;
    faSpinner = faSpinner;

    constructor(
        private dialogRef: MatDialogRef<ConfirmNoteDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private notificationService: NotificationService,
        private _formBuilder: FormBuilder,
    ) {
        this.title = data.title;
        this.id = data.id;
        this.storeOrderId = data.storeOrderId;
        this.isEditNote = !!data.isEditNote;
        this.noteId = this.isEditNote ? data.noteId : '';
        this.editText = this.isEditNote ? data.editText : '';
        this.label = data.label ? data.label : 'COMMON.REASON';
        this.placeholder = data.placeholder ? data.placeholder : 'DESCRIBE_A_REASON';
        this.action = data.action;
        this.multipleAction = data.multipleAction;
        this.message = data.message ? data.message : "";
        this.messageBlack = !!data.messageBlack;
        this.textCenterTitle = !!data.textCenterTitle;
        this.subMessage = data.subMessage ? data.subMessage : "";
        this.confirmAction = data.confirmAction ? data.confirmAction : "YES";
        this.cancelAction = data.cancelAction ? data.cancelAction : "NO";
        this.isWarnBotton = data.isWarnBotton ? data.isWarnBotton : false;
        this.notificationSuccess = data.notificationSuccess
            ? data.notificationSuccess
            : "ACTION_COMPLETED_SUCCESSFULLY";
        this.notificationFailMultiple = data.notificationFailMultiple;
    }

    ngOnInit(): void {
        if (this.isEditNote) {
            this.form = new FormGroup<any>({
                note: new FormControl(this.editText, Validators.required)
            });
        } else {
            this.form = this._formBuilder.group({
                note: new FormControl(null, Validators.required)
            });
        }
    }

    onSubmit(): void {
        this.isLoading = true;
        const formData = this.form.getRawValue();
        if (!this.action){
            this.isLoading = false;
            this.dialogRef.close(formData.note);
        }
        if (!this.isEditNote) {
            if (this.storeOrderId) {
                this.action(this.id, this.storeOrderId, formData.note).subscribe((response: any) => {
                    if (response.success) {
                        this.notificationService.success(this.notificationSuccess);
                    } else {
                        this.notificationService.error(response.message);
                    }
                    this.isLoading = false;
                    this.dialogRef.close(response);
                }, (error: any) => {
                    this.isLoading = false;
                    this.notificationService.error(error.error.message);
                });
            } else {
                this.action(this.id, formData.note).subscribe((response: any) => {
                    if (this.multipleAction) {
                        const responseArray: any[] = Object.values(response);
                        if (responseArray.reduce((acc, cur) => acc.success && cur.success)) {
                            this.notificationService.success(this.notificationSuccess);
                        } else {
                            this.notificationService.error(this.notificationFailMultiple);
                        }
                    } else {
                        if (response.success) {
                            this.notificationService.success(this.notificationSuccess);
                        } else {
                            this.notificationService.error(response.message);
                        }
                    }
                    this.isLoading = false;
                    this.dialogRef.close(response);
                }, (error: any) => {
                    this.isLoading = false;
                    this.notificationService.error(error.error.message);
                });
            }
        } else {
            this.action(this.id, this.noteId, formData.note).subscribe((response: any) => {
                if (this.multipleAction) {
                    const responseArray: any[] = Object.values(response);
                    if (responseArray.reduce((acc, cur) => acc.success && cur.success)) {
                        this.notificationService.success(this.notificationSuccess);
                    } else {
                        this.notificationService.error(this.notificationFailMultiple);
                    }
                } else {
                    if (response.success) {
                        this.notificationService.success(this.notificationSuccess);
                    } else {
                        this.notificationService.error(response.message);
                    }
                }
                this.isLoading = false;
                this.dialogRef.close(response);
            }, (error: any) => {
                this.isLoading = false;
                this.notificationService.error(error.error.message);
            });
        }
    }
    close(): void {
        this.dialogRef.close(null);
    }
}
