import {Injectable} from '@angular/core';
import {of, Observable, Subject} from "rxjs";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {AuthService} from "../../core/auth/auth.service";
import {BaseDataGrid} from "../utils/base-data-grid";
import {BaseDataGridService} from "../utils/base-data-grid.service";
import {InternalNotificationDto} from "../interfaces/internalNotificationDto.interface";
import {
    AddNotificationDto
} from "../../modules/internal-notifications/components/notification-form/add-notification-dto";
// import {AuthService} from "../../../shared/services/auth.service";
// import {InternalNotificationDto} from "../notifications/internal-notifications/internalNotificationDto.interface";

const URL = environment?.api_service + environment?.v1 + '/management/notification/internal'
const RESOURCE = 'InternalNotifications'

@Injectable({
    providedIn: 'root'
})
export class InternalNotificationService extends BaseDataGridService{
    resource: string = RESOURCE;
    url: string = URL;
    urlEnum: string = environment.api_service + environment.v1 + '/discovery/enum';

    private readOrDeleteEventSubject = new Subject<number>()
    readOrDeleteEvent = this.readOrDeleteEventSubject.asObservable()


    addEventSubject = new Subject<InternalNotificationDto>()
    addEvent = this.addEventSubject.asObservable()

    private GET_UNREAD_COUNT = URL + '/unreads' // This is a get and returns an object with the count in data.count
    private MARK_AS_READ = URL + '/markread' // This is a post and expects an array of ids in the body
    private DELETE = URL + '/delete' // This is a delete and expects an array of ids in the body
    private GET_NOTIFICATIONS_URL = URL
    private ADD_NOTIFICATIONS_URL = URL


    constructor(private http: HttpClient, private authService: AuthService,
    ) {
        super(URL, RESOURCE, http);
    }

    getNotifications(count: number, allNotifications: boolean, page: number): Observable<any> {
        //Get headers
        const headers = new HttpHeaders()
            .set('Cache-Control', 'no-cache')
            .set('Pragma', 'no-cache')
            .set('x-refresh', 'true');

        const params = {OffsetPage: page, pageSize: count.toString(), GetRead: allNotifications};
        return this.http.get(this.GET_NOTIFICATIONS_URL, {headers: headers, params: params});
    }

    getUnreadCount(): Observable<any> {
        const headers = new HttpHeaders()
            .set('Cache-Control', 'no-cache')
            .set('Pragma', 'no-cache')
            .set('x-refresh', 'true');

        return this.http.get(this.GET_UNREAD_COUNT, {headers: headers});
    }

    markAsRead(ids: string[]): Observable<any> {
        this.readOrDeleteEventSubject.next(ids.length)
        return this.http.put(this.MARK_AS_READ, {ids});
    }

    deleteNotifications(ids: string[]): Observable<any> {
        this.readOrDeleteEventSubject.next(ids.length)
        return this.http.request('delete', this.DELETE, {body: {ids}});
    }

    getEnum(enumName: string) {
        const httpParams = new HttpParams().set('EnumName', enumName);
        return this._httpClient.get(this.urlEnum, {params: httpParams});
    }

    add(notification: AddNotificationDto): Observable<any> {
        const headers = new HttpHeaders()
            .set('Cache-Control', 'no-cache')
            .set('Pragma', 'no-cache')
            .set('x-refresh', 'true');

        return this.http.post(this.ADD_NOTIFICATIONS_URL, {notification: notification}, {headers: headers});
    }
}
