<div [formGroup]="userForm" class="flex gap-1">
    <mat-form-field appearance="outline" class="w-full">
        <mat-label>{{ 'USER_SEARCH_BY_EMAIL' | transloco }}</mat-label>
        <input matInput [matAutocomplete]="auto" formControlName='userId'
               placeholder="{{ 'SEARCH' | transloco }}">
    </mat-form-field>
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayUserFn">
        <mat-option *ngIf="isLoadingUser" class="is-loading">
            <fa-icon
                class="relative flex justify-center items-center animate-spin rounded-full text-accent-800 text-3xl"
                [icon]="faSpinner">
            </fa-icon>
        </mat-option>
        <ng-container *ngIf="!isLoadingUser">
            <mat-option *ngFor="let user of filteredUsers" [value]="user">
                <small>{{user.email}}</small>
            </mat-option>
        </ng-container>
    </mat-autocomplete>
</div>
