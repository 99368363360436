import { Component, OnInit, Inject } from "@angular/core";
import {
    MatDialogRef,
    MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NotificationService} from "../../services/notifications.service";
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import {VerificationService} from "../../../modules/verification/services/verification.service";
import {VerificationActionEnum, VerificationEnum} from "../../enums/verification.enum";

@Component({
    selector: "app-approve-and-deny-verification-dialog-component",
    templateUrl: "./approve-and-deny-verification-dialog.component.html"
})
export class ApproveAndDenyVerificationDialogComponent implements OnInit {
    isLoading: boolean = false;
    title: string;
    type: string;
    approve: boolean;
    action: any;
    confirmAction: string;
    cancelAction: string;
    notificationSuccess: string;
    isWarnBotton: boolean;
    id: string;
    isDocument: boolean = false;

    form: FormGroup = new FormGroup<any>({
        arguments: new FormControl(null, Validators.required)
    });
    faSpinner = faSpinner;

    constructor(
        private dialogRef: MatDialogRef<ApproveAndDenyVerificationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private notificationService: NotificationService,
        private _formBuilder: FormBuilder,
        private verificationService: VerificationService
    ) {
        this.type = data.type;
        this.id = data.id;
        this.approve = data.approve;
        this.title = this.approve ? 'COMMON.APPROVE' : 'DENY';
        this.action = data.action;
        this.confirmAction = this.approve ? 'COMMON.APPROVE' : 'DENY';
        this.cancelAction = 'COMMON.CANCEL';
        this.isWarnBotton = !this.approve;
        this.notificationSuccess = this.approve ? 'VERIFICATION.VERIFICATION_APPROVED_SUCCESS' : 'VERIFICATION.VERIFICATION_DENIED_SUCCESS';
        this.isDocument = !!(data && data.document);
    }

    ngOnInit(): void {}

    onSubmit(): void {
        this.isLoading = true;
        const dataForm = this.form.getRawValue();
        if (this.type === VerificationEnum.USERS) {
            if (this.approve) {
                this.verificationService.approve({id: this.id, arguments: dataForm.arguments}).subscribe((response: any) => {
                    this.methodResponse(response);
                }, (error: any) => {
                    this.isLoading = false;
                    this.notificationService.error(error.error.message);
                })
            } else {
                this.verificationService.deny({id: this.id, arguments: dataForm.arguments}).subscribe((response: any) => {
                    this.methodResponse(response);
                }, (error: any) => {
                    this.isLoading = false;
                    this.notificationService.error(error.error.message);
                })
            }
        } else if (this.type === VerificationEnum.DOCUMENTS) {
            if (this.approve) {
                this.verificationService.approveDocument({id: this.id, arguments: dataForm.arguments}).subscribe((response: any) => {
                    this.methodResponse(response);
                }, (error: any) => {
                    this.isLoading = false;
                    this.notificationService.error(error.error.message);
                })
            } else {
                this.verificationService.denyDocument({id: this.id, arguments: dataForm.arguments}).subscribe((response: any) => {
                    this.methodResponse(response);
                }, (error: any) => {
                    this.isLoading = false;
                    this.notificationService.error(error.error.message);
                })
            }
        }
    }

    methodResponse(response: any) {
        if (response.success) {
            this.notificationService.success(this.notificationSuccess);
        } else {
            this.notificationService.error(response.message);
        }
        this.isLoading = false;
        this.dialogRef.close(response);
    }

    close(): void {
        this.dialogRef.close(null);
    }
}
