import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormControl, FormGroup, Validators} from '@angular/forms';
import {NotificationService} from "../../services/notifications.service";
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import {VerificationService} from "../../../modules/verification/services/verification.service";
import {debounceTime} from "rxjs/operators";
import {ClipboardService} from "ngx-clipboard";

@Component({
    selector: "app-start-verification-dialog-component",
    templateUrl: "./start-verification-dialog.component.html"
})
export class StartVerificationDialogComponent implements OnInit {
    isLoading: boolean = false;

    // isActiveWallet: boolean = false;
    // isBlockedWallet: boolean = true;

    isLoadingUser: boolean = false;
    filteredUsers: any[] = [];
    filteredDefaultUsers: any[] = [];
    isValidatingUser: boolean = false;
    isGetDefaultUsers: boolean = false;

    form: FormGroup = new FormGroup<any>({
        userId: new FormControl<string>(null, Validators.required),
        firstname: new FormControl<string>(null, Validators.required),
        lastname: new FormControl<string>(null, Validators.required),
    });
    faSpinner = faSpinner;
    onfidoUrl: string | null = null;

    constructor(
        private dialogRef: MatDialogRef<StartVerificationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private notificationService: NotificationService,
        private verificationService: VerificationService,
        private clipboardService: ClipboardService,
    ) {

    }

    ngOnInit(): void {
        this.form.get('userId').valueChanges
            .pipe(debounceTime(400))
            .subscribe(value => {
                if (!this.isValidatingUser) {
                    if (value || (value == '' && !this.isGetDefaultUsers)) {
                        this.isLoadingUser = true;
                        if (value instanceof Object) {
                            this.filteredUsers = [];
                            this.isLoadingUser = false;
                            // this.getWalletByUserId(value.id);
                        } else {
                            this.onfidoUrl = null;
                            this.verificationService.searchUsers(value.fullName ? value.fullName : value).subscribe((users: any) => {
                                this.filteredUsers = users.data.items;
                                if (value == '' && !this.isGetDefaultUsers) {
                                    this.filteredDefaultUsers = users.data.items;
                                    this.isGetDefaultUsers = true;
                                }
                                this.isLoadingUser = false;
                                this.isValidatingUser = true;
                                this.form.get('userId').updateValueAndValidity();
                            });
                        }
                    } else if (value == '' && this.isGetDefaultUsers) {
                        this.filteredUsers = [...this.filteredDefaultUsers];
                    }
                } else {
                    this.isValidatingUser = false;
                }
            });
    }

    open() {
        window.open(this.onfidoUrl, '_blank');
    }

    callServiceToCopy() {
        this.clipboardService.copy(this.onfidoUrl);
        this.notificationService.success("COMMON.TEXT_COPIED_TO_CLIPBOARD");
    }

    onSubmit(): void {
        this.isLoading = true;
        this.form.disable();
        const formData = this.form.getRawValue();
        const data = {
            userId: formData.userId.id,
            firstname: formData.firstname,
            lastname: formData.lastname,
        };
        this.verificationService.startVerification(data).subscribe({
            next: (response: any) => {
                if (response.success) {
                    this.isLoading = false;
                    this.notificationService.success('START_VERIFICATION_SUCCESS');
                    // this.dialogRef.close(response);
                    this.onfidoUrl = response.data;
                } else {
                    this.isLoading = false;
                    this.notificationService.error(response.message);
                }
                this.form.enable();
            },
            error: (error: any) => {
                this.notificationService.error(error.error.message);
                this.isLoading = false;
                this.form.enable();
            }
        });
    }

    isObject(): boolean {
        return this.form.get('userId').value instanceof Object;
    }

    displayUserFn(user: any) {
        if (user) {
            return user.email;
        }
    }

    close(): void {
        this.dialogRef.close(null);
    }
}
