/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'apps',
        title: 'COMMON.APPLICATIONS',
        subtitle: 'Custom made application designs',
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [
            {
                id: 'dashboard',
                title: 'DASHBOARD.DASHBOARD',
                type: 'basic',
                icon: 'heroicons_outline:chart-pie',
                link: '/dashboard',
                permissionKey: 'DashboardsAccess-Dashboard'
            },
            {
                id: 'marketplace',
                title: 'MARKETPLACE.MARKETPLACE',
                type: 'collapsable',
                icon: 'mat_solid:store',
                children: [
                    {
                        id: 'shopping-cart',
                        title: 'SHOPPING_CARTS',
                        type: 'basic',
                        // icon: 'mat_outline:shopping_cart',
                        link: '/marketplace/shopping-carts',
                        permissionKey: 'GetShoppingCartListQuery'
                    },
                    {
                        id: 'orders',
                        title: 'COMMON.ORDERS',
                        type: 'collapsable',
                        children: [
                            {
                                id: 'orders',
                                title: 'COMMON.ORDERS',
                                type: 'basic',
                                link: '/marketplace/orders',
                                permissionKey: 'ManagementStoreOrderListQuery'
                            },
                            {
                                id: 'order-incidents',
                                title: 'ORDER_INCIDENTS',
                                type: 'basic',
                                link: '/marketplace/order-incidents',
                                permissionKey: 'OrderIncidentListQuery'
                            },

                            // {
                            //     id: 'orders-created',
                            //     title: 'MARKETPLACE.ORDERS_CREATED',
                            //     type: 'basic',
                            //     link: '/marketplace/orders-created',
                            //     permissionKey: 'ManagementStoreOrderListCreatedQuery'
                            // },
                            {
                                id: 'orders-confirmed',
                                title: 'COMMON.ORDERS_CONFIRMED',
                                type: 'basic',
                                link: '/marketplace/orders-confirmed',
                                permissionKey: 'ManagementStoreOrderListConfirmedQuery'
                            },
                            {
                                id: 'orders-pending',
                                title: 'COMMON.ORDERS_PENDING',
                                type: 'basic',
                                link: '/marketplace/orders-pending',
                                permissionKey: 'ManagementStoreOrderListPendingQuery'
                            },
                            {
                                id: 'orders-expired',
                                title: 'MARKETPLACE.ORDERS_EXPIRED',
                                type: 'basic',
                                link: '/marketplace/orders-expired',
                                permissionKey: 'ManagementStoreOrderListExpiredQuery'
                            },
                            {
                                id: 'orders-cancelled',
                                title: 'MARKETPLACE.ORDERS_CANCELLED',
                                type: 'basic',
                                link: '/marketplace/orders-cancelled',
                                permissionKey: 'ManagementStoreOrderListCancelledQuery'
                            },
                        ]
                    },
                    {
                        id: 'shipments',
                        title: 'SHIPMENTS',
                        type: 'collapsable',
                        children: [
                            {
                                id: 'shipments',
                                title: 'SHIPMENTS',
                                type: 'basic',
                                link: '/marketplace/shipments',
                                permissionKey: 'ManagementOrderShipmentsListQuery'
                            },
                            // {
                            //     id: 'shipments-created',
                            //     title: 'SHIPMENTS_CREATED',
                            //     type: 'basic',
                            //     link: '/marketplace/shipments-created',
                            //     permissionKey: 'ManagementOrderShipmentsListCreatedQuery'
                            // },
                            // {
                            //     id: 'shipments-confirmed',
                            //     title: 'SHIPMENTS_CONFIRMED',
                            //     type: 'basic',
                            //     link: '/marketplace/shipments-confirmed',
                            //     permissionKey: 'ManagementOrderShipmentsListComfirmedQuery'
                            // },
                            // {
                            //     id: 'shipments-sent',
                            //     title: 'SHIPMENTS_SENT',
                            //     type: 'basic',
                            //     link: '/marketplace/shipments-sent',
                            //     permissionKey: 'ManagementOrderShipmentsListShippedQuery'
                            // },
                            // {
                            //     id: 'shipments-delivered',
                            //     title: 'SHIPMENTS_DELIVERED',
                            //     type: 'basic',
                            //     link: '/marketplace/shipments-delivered',
                            //     permissionKey: 'ManagementOrderShipmentsListDeliveredQuery'
                            // },
                            // {
                            //     id: 'shipments-cancelled',
                            //     title: 'SHIPMENTS_CANCELLED',
                            //     type: 'basic',
                            //     link: '/marketplace/shipments-cancelled',
                            //     permissionKey: 'ManagementOrderShipmentsListCancelledQuery'
                            // },
                        ]
                    },
                    {
                        id: 'inventory-adjusts',
                        title: 'INVENTORY_ADJUSTS',
                        type: 'basic',
                        link: '/marketplace/inventory-adjusts',
                        permissionKey: 'OrderInventoryAdjustListQuery'
                    },
                ]
            },
            {
                id: 'payments',
                title: 'COMMON.PAYMENTS',
                type: 'collapsable',
                icon: 'mat_outline:payment',
                children: [
                    {
                        id: 'payments',
                        title: 'COMMON.PAYMENTS',
                        type: 'basic',
                        // icon : 'heroicons_outline:credit-card',
                        link: '/payments',
                        exactMatch: true,
                        permissionKey: 'GetPaymentsQuery'
                    },
                    {
                        id: 'pending-payments',
                        title: 'COMMON.PENDING_PAYMENTS',
                        type: 'basic',
                        // icon : 'heroicons_outline:credit-card',
                        link: '/pending-payments',
                        permissionKey: 'GetPaymentsQuery'
                    },
                    {
                        id: 'external-payments',
                        title: 'EXTERNAL_PAYMENT.EXTERNAL_PAYMENTS',
                        type: 'basic',
                        link: '/external-payments',
                        permissionKey: 'GetExternalPaymentOrdersQuery'
                    },
                    {
                        id: 'payment-cards',
                        title: 'COMMON.PAYMENT_CARDS',
                        type: 'basic',
                        // icon : 'heroicons_outline:credit-card',
                        link: '/payment-cards',
                        permissionKey: 'GetPaymentCardsQuery'
                    },
                    {
                        id: 'payment-requests',
                        title: 'PAYMENT_REQUESTS',
                        type: 'basic',
                        link: '/payment-requests',
                        permissionKey: 'GetPaymentRequestsQuery'
                    },
                    {
                        id: 'card-limits',
                        title: 'CARD.CARD_LIMITS',
                        type: 'basic',
                        // icon : 'heroicons_outline:credit-card',
                        link: '/card-limits',
                        permissionKey: 'GetUserCardLimitQuery'
                    },
                    {
                        id: 'failed-payment-attempts',
                        title: 'COMMON.FAILED_PAYMENT_ATTEMPTS',
                        type: 'basic',
                        // icon : 'heroicons_outline:credit-card',
                        link: '/failed-cards',
                        permissionKey: 'GetFailedCardsQuery'
                    },
                    {
                        id: 'wallet',
                        title: 'WALLET',
                        type: 'collapsable',
                        children: [
                            {
                                id: 'wallet-usd',
                                title: 'WALLET_USD',
                                type: 'basic',
                                link: '/wallet/usd',
                                permissionKey: 'GetUserWalletQuery'
                            },
                            {
                                id: 'wallet-mlc',
                                title: 'WALLET_MLC',
                                type: 'basic',
                                link: '/wallet/mlc',
                                permissionKey: 'GetUserWalletQuery'
                            },
                            {
                                id: 'wallet-cup',
                                title: 'WALLET_CUP',
                                type: 'basic',
                                link: '/wallet/cup',
                                permissionKey: 'GetUserWalletQuery'
                            },
                        ]
                    },
                    {
                        id: 'wallet-operations',
                        title: 'WALLET.WALLET_OPERATIONS',
                        type: 'basic',
                        // icon : 'heroicons_outline:credit-card',
                        link: '/wallet-operations',
                        permissionKey: 'GetKwalletOperationsQuery'
                    },
                    {
                        id: 'conciliations',
                        title: 'CONCILIATIONS.CONCILIATIONS',
                        type: 'basic',
                        // icon : 'heroicons_outline:credit-card',
                        link: '/payments/conciliations',
                        exactMatch: true,
                        permissionKey: 'GetPaymentsQuery'
                    },
                    {
                        id: 'conciliations-sm23',
                        title: 'CONCILIATIONS_SM23',
                        type: 'basic',
                        // icon : 'heroicons_outline:credit-card',
                        link: '/payments/conciliations-sm23',
                        exactMatch: true,
                        permissionKey: 'ExternalMarketConciliationsQuery'
                    },
                ]
            },
            {
                id: 'users',
                title: 'COMMON.USERS',
                type: 'basic',
                icon: 'heroicons_outline:user-circle',
                link: '/users',
                permissionKey: 'GetUsersQuery'
            },
            {
                id: 'access-groups',
                title: 'COMMON.ACCESS_GROUPS',
                type: 'basic',
                icon: 'heroicons_outline:user-group',
                link: '/access-groups',
                permissionKey: 'GetAccessGroupsQuery'
            },
            {
                id: 'notifications',
                title: 'NOTIFICATIONS',
                type: 'basic',
                icon: 'heroicons_outline:bell',
                link: '/notifications',
            },
            {
                id: 'audits',
                title: 'AUDITS.AUDITS',
                type: 'basic',
                icon: 'heroicons_outline:credit-card',
                link: '/audits',
                permissionKey: 'GetAuditLogsQuery'
            },
            {
                id: 'dead-messages',
                title: 'MESSAGES.DEAD_MESSAGES',
                type: 'basic',
                icon: 'mat_outline:message',
                link: '/dead-messages',
                permissionKey: 'DeadLetterMessagesQuery'
            },
            {
                id: 'alerts',
                title: 'ALERT.ALERTS',
                type: 'collapsable',
                icon: 'mat_outline:add_alert',
                children: [
                    {
                        id: 'groups',
                        title: 'GROUPS',
                        type: 'basic',
                        link: '/alerts/groups',
                        permissionKey: 'GetAlertGroupsQuery'
                    },
                    {
                        id: 'receivers',
                        title: 'ALERT.RECEIVERS',
                        type: 'basic',
                        link: '/alerts/receivers',
                        permissionKey: 'GetAlertReceiversQuery'
                    },
                    {
                        id: 'records',
                        title: 'COMMON.RECORDS',
                        type: 'basic',
                        link: '/alerts/records',
                        permissionKey: 'GetAlertRecordsQuery'
                    },
                    {
                        id: 'query-definitions',
                        title: 'ALERT.QUERY_DEFINITIONS',
                        type: 'basic',
                        link: '/alerts/query-definitions',
                        permissionKey: 'GetAlertByQueryDefinitionsQuery'
                    }
                ]
            },
            {
                id: 'reports',
                title: 'REPORT.REPORTS',
                type: 'collapsable',
                icon: 'heroicons_outline:chart-bar',
                children: [
                    {
                        id: 'payments',
                        title: 'COMMON.PAYMENTS',
                        type: 'basic',
                        link: '/reports/payments',
                        permissionKey: 'GetReportPayments' // GetReportsStores
                    },
                    {
                        id: 'payments',
                        title: 'COMMON.PAYMENTS',
                        type: 'basic',
                        link: '/reports/payments',
                        permissionKey: 'GetReportPagos' // GetReportsStores
                    },
                    {
                        id: 'stores',
                        title: 'COMMON.STORES',
                        type: 'basic',
                        link: '/reports/stores',
                        permissionKey: 'GetReportLinkQuery',
                    },
                ]
            },
            {
                id: 'gift-cards',
                title: 'GIFT_CARD.GIFT_CARDS',
                type: 'collapsable',
                icon: 'heroicons_outline:gift',
                children: [
                    {
                        id: 'gift-cards',
                        title: 'GIFT_CARD.GIFT_CARDS',
                        type: 'basic',
                        link: '/gift-cards/list',
                        permissionKey: 'GetGiftCardListQuery'
                    },
                    {
                        id: 'gift-cards-orders',
                        title: 'COMMON.GIFT_CARD_ORDERS',
                        type: 'basic',
                        link: '/gift-cards/orders',
                        permissionKey: 'GetGiftCardOrderListQuery'
                    },
                    {
                        id: 'gift-cards-products',
                        title: 'PRODUCTS',
                        type: 'basic',
                        link: '/gift-cards/products',
                        permissionKey: 'GetGiftCardProductListQuery'
                    }
                ]
            },
            {
                id: 'la-nave',
                title: 'La Nave',
                type: 'collapsable',
                icon: 'heroicons_outline:gift',
                children: [
                    // {
                    //     id: 'la-nave',
                    //     title: 'La Nave',
                    //     type: 'basic',
                    //     link: '/gift-cards/list',
                    //     permissionKey: 'GetGiftCardListQuery'
                    // },
                    {
                        id: 'la-nave-orders',
                        title: 'LA_NAVE.LA_NAVE_ORDERS',
                        type: 'basic',
                        link: '/la-nave/orders',
                        permissionKey: 'GetGiftCardOrderListQuery'
                    }
                ]
            },
            {
                id: 'cubacel-recharges',
                title: 'CUBACEL_RECHARGES',
                type: 'collapsable',
                icon: 'mat_outline:attach_money',
                children: [
                    {
                        id: 'cubacel-recharges-orders',
                        title: 'COMMON.ORDERS',
                        type: 'basic',
                        link: '/cubacel/orders',
                        permissionKey: 'GetTopUpOrdersQuery',
                    },
                    {
                        id: 'cubacel-confirmed-orders',
                        title: 'COMMON.ORDERS_CONFIRMED',
                        type: 'basic',
                        link: '/cubacel/confirmed-orders',
                        permissionKey: 'CubacelTopUpQuery',
                    },
                    {
                        id: 'cubacel-pending-orders',
                        title: 'COMMON.ORDERS_PENDING',
                        type: 'basic',
                        link: '/cubacel/confirm-pending-orders',
                        permissionKey: 'CubacelTopUpQuery',
                    },
                    {
                        id: 'cubacel-recharges',
                        title: 'RECHARGES',
                        type: 'basic',
                        link: '/cubacel/recharges',
                        permissionKey: 'CubacelTopUpQuery',
                    },
                    {
                        id: 'recharges-to-process',
                        title: 'RECHARGES_TO_PROCESS',
                        type: 'basic',
                        link: '/cubacel/recharges-to-process',
                        permissionKey: 'CubacelTopUpQuery',
                    },
                    {
                        id: 'cubacel-products',
                        title: 'PRODUCTS',
                        type: 'basic',
                        link: '/cubacel/products',
                        permissionKey: 'GetTopUpProductsQuery',
                    },
                ]
            },
            {
                id: 'risk-records',
                title: 'RISK_SCORE.RISK_RECORDS',
                type: 'basic',
                icon: 'feather:activity',
                link: '/risk-records',
                permissionKey: 'GetRiskRecordsQuery'
            },
            {
                id: 'black-list',
                title: 'BLACK_LIST.BLACK_LIST',
                type: 'basic',
                icon: 'mat_outline:format_list_bulleted',
                link: '/black-list',
                permissionKey: 'GetBlacklistRecordsQuery'
            },
            {
                id: 'app-remittances',
                title: 'REMITTANCE.REMITTANCES',
                type: 'collapsable',
                icon: 'mat_outline:attach_money',
                children: [
                    {
                        id: 'all-remittances',
                        title: 'REMITTANCE.ALL_REMITTANCES',
                        type: 'basic',
                        link: '/remittances/list/all',
                        permissionKey: 'GetRemittanceListQuery'
                    },
                    {
                        id: 'remittances-completed',
                        title: 'REMITTANCE.REMITTANCES_COMPLETE',
                        type: 'basic',
                        link: '/remittances/list/completed',
                        permissionKey: 'GetRemittanceManagementByStatusCompleteQuery'
                    },
                    {
                        id: 'remittances-incomplete',
                        title: 'REMITTANCE.REMITTANCES_INCOMPLETE',
                        type: 'basic',
                        link: '/remittances/list/incomplete',
                        permissionKey: 'GetRemittanceManagementByStatusIncompleteQuery'
                    },
                    {
                        id: 'remittances-processing',
                        title: 'REMITTANCE.REMITTANCES_PROCESSING',
                        type: 'basic',
                        link: '/remittances/list/processing',
                        permissionKey: 'GetRemittanceManagementByStatusProcessingQuery'
                    },
                ]
            },
            {
                id: 'users-verifications',
                title: 'USER.USERS_VERIFICATIONS',
                type: 'collapsable',
                icon: 'mat_outline:verified_user',
                children: [
                    {
                        id: 'user-verifications',
                        title: 'USER.USER_VERIFICATIONS',
                        type: 'basic',
                        link: '/remittances/verifications/user-verifications',
                        permissionKey: 'GetUserVerificationsQuery'
                    },
                    {
                        id: 'pending-verifications',
                        title: 'VERIFICATION.PENDING_VERIFICATIONS',
                        type: 'basic',
                        link: '/remittances/verifications/pending-verifications',
                        permissionKey: 'GetUserVerificationsQuery'
                    },
                    {
                        id: 'pending-documents',
                        title: 'DOCUMENT.PENDING_DOCUMENTS',
                        type: 'basic',
                        link: '/remittances/pending-documents',
                        permissionKey: 'GetPendingDocumentVerificationsQuery'
                    },
                ]
            },
            {
                id: 'incidents',
                title: 'INCIDENTS',
                type: 'collapsable',
                icon: 'heroicons_outline:shield-exclamation',
                children: [
                    {
                        id: 'user-incidents',
                        title: 'INCIDENTS',
                        type: 'basic',
                        link: '/incidents/list',
                        permissionKey: 'GetUserIncidentsQuery',
                    },
                    {
                        id: 'incident-groups',
                        title: 'INCIDENT_GROUPS',
                        type: 'basic',
                        link: '/incidents/groups',
                        permissionKey: 'GetUserIncidentsQuery',
                    },
                    {
                        id: 'incident-managed-groups',
                        title: 'INCIDENT_GROUPS_SOLVED',
                        type: 'basic',
                        link: '/incidents/managed-groups',
                        permissionKey: 'GetUserIncidentsQuery'
                    },
                    {
                        id: 'incident-conciliations',
                        title: 'INCIDENT_CONCILIATIONS',
                        type: 'basic',
                        link: '/incidents/conciliations',
                        permissionKey: 'GetUserIncidentsQuery'
                    }
                ]
            },
            {
                id: 'promoCode',
                title: 'PROMO_CODES',
                type : 'basic',
                icon : 'heroicons_outline:gift',
                link : '/promo-codes',
                permissionKey: 'GetStorePromotionsQuery'
            },
        ],
    },
    {
        id: 'setting',
        title: 'SETTING.SETTING',
        subtitle: 'Custom made application designs',
        type: 'group',
        icon: 'heroicons_outline:cog',
        children: [
            {
                id: 'marketplace',
                title: 'Marketplace',
                type: 'collapsable',
                // icon: 'mat_outline:storefront',
                icon: 'mat_outline:category',
                children: [
                    {
                        id: 'stores',
                        title: 'COMMON.STORES',
                        type: 'basic',
                        link: '/settings-marketplace/stores',
                        permissionKey: ''
                    },
                    {
                        id: 'products',
                        title: 'PRODUCTS',
                        type: 'basic',
                        link: '/settings-marketplace/products',
                        permissionKey: ''
                    },
                    {
                        id: 'nomenclatures',
                        title: 'NOMENCLATURES',
                        type: 'collapsable',
                        // icon: 'mat_outline:category',
                        children: [
                            {
                                id: 'delivery-times',
                                title: 'DELIVERY_TIMES',
                                type: 'basic',
                                link: '/settings-marketplace/nomenclatures/delivery-times',
                                permissionKey: 'StoreDeliveryTimeQuery'
                            },
                            {
                                id: 'categories',
                                title: 'CATEGORIES',
                                type: 'basic',
                                link: '/settings-marketplace/nomenclatures/categories',
                                permissionKey: 'ProductCategoryListQuery'
                            },
                            {
                                id: 'variants-options',
                                title: 'VARIANTS_OPTIONS_PRODUCTS',
                                type: 'basic',
                                link: '/settings-marketplace/nomenclatures/variants-options',
                                permissionKey: 'StoreVariantOptionListQuery'
                            },
                            {
                                id: 'providers',
                                title: 'PROVIDERS',
                                type: 'basic',
                                link: '/settings-marketplace/nomenclatures/providers',
                                permissionKey: 'ProvidersListQuery'
                            },
                            {
                                id: 'shipping-methods',
                                title: 'COMMON.SHIPPING_METHOD',
                                type: 'basic',
                                link: '/settings-marketplace/nomenclatures/shipping-methods',
                                permissionKey: 'StoreShippingMethodListQuery'
                            },
                            {
                                id: 'search-groups',
                                title: 'SEARCH_GROUPS',
                                type: 'basic',
                                link: '/settings-marketplace/nomenclatures/search-groups',
                                permissionKey: ''
                            },
                            {
                                id: 'history-multiple-editions',
                                title: 'HISTORY_MULTIPLE_EDITION',
                                type: 'basic',
                                link: '/settings-marketplace/nomenclatures/history-multiple-editions',
                                permissionKey: ''
                            },
                        ]
                    },
                ],
            },
            {
                id: 'general-ssettings',
                title: 'SETTING.GENERAL_SETTINGS',
                type: 'basic',
                icon: 'heroicons_outline:cog',
                link: '/settings',
                permissionKey: 'GetSettingsQuery'
            },
            {
                id: 'payment-routes',
                title: 'PAYMENT.PAYMENT_ROUTES',
                type: 'basic',
                icon: 'mat_outline:alt_route',
                link: '/payment-routes',
                permissionKey: 'GetPaymentRouteQuery'
            },
            {
                id: 'payment-route-strategy',
                title: 'PAYMENT_ROUTES.PAYMENT_ROUTES_STRATEGIES',
                type: 'basic',
                icon: 'mat_solid:payments',
                link: '/payment-routes-strategies',
                permissionKey: 'GetMyPaymentRouteStrategyQuery'
            },
            {
                id: 'payment-clients',
                title: 'PAYMENT.PAYMENT_CLIENTS',
                type: 'basic',
                icon: 'heroicons_outline:currency-dollar',
                link: '/payment-clients',
                permissionKey: 'GetPaymentClientsQuery'
            },
            {
                id: 'merchants',
                title: 'MERCHANTS.MERCHANTS',
                type: 'basic',
                icon: 'mat_outline:payments',
                link: '/merchants',
                permissionKey: 'GetMerchantsQuery'
            },
            {
                id: 'frontend-feature',
                title: 'FRONTEND_FEATURE.FRONTEND_FEATURE',
                type: 'basic',
                icon: 'heroicons_outline:sparkles',
                link: '/frontend-feature',
                permissionKey: 'GetFrontendFeaturesQuery'
            },
            {
                id: 'notification-template',
                title: 'NOTIFICATION_TEMPLATES',
                type: 'basic',
                icon: 'heroicons_outline:clipboard-list',
                link: '/notification-template',
                permissionKey: 'GetNotificationTemplatesQuery'
            },
            {
                id: 'remittances',
                title: 'REMITTANCE.REMITTANCES',
                type: 'collapsable',
                icon: 'mat_outline:attach_money',
                children: [
                    {
                        id: 'currencies',
                        title: 'CURRENCY.CURRENCIES',
                        type: 'basic',
                        link: '/remittances/currencies',
                        permissionKey: 'GetCurrencyTypesQuery'
                    },
                    {
                        id: 'countries',
                        title: 'COUNTRY.COUNTRIES',
                        type: 'basic',
                        link: '/remittances/countries',
                        permissionKey: 'GetCountriesQuery'
                    },
                    {
                        id: 'delivery-types',
                        title: 'DELIVERY.DELIVERY_TYPES',
                        type: 'basic',
                        link: '/remittances/delivery-types',
                        permissionKey: 'GetDeliveryMethodsTypesQuery'
                    },
                    {
                        id: 'payment-types',
                        title: 'PAYMENT.PAYMENT_METHOD_TYPES',
                        type: 'basic',
                        link: '/remittances/payment-types',
                        permissionKey: 'GetPaymentMethodTypesQuery'
                    },
                ]
            },
            {
                id: 'external-payment-balance',
                title: 'EXTERNAL_PAYMENT.EXTERNAL_PAYMENT_BALANCE',
                type: 'collapsable',
                icon: 'mat_outline:account_balance',
                children: [
                    {
                        id: 'external-payment-balance-active',
                        title: 'COMMON.ACTIVES',
                        type: 'basic',
                        link: '/external-payment-balance/actives',
                        permissionKey: 'GetActiveExternalPaymentBalance',
                    },
                    {
                        id: 'external-payment-balance-all',
                        title: 'USER.ALL',
                        type: 'basic',
                        link: '/external-payment-balance/all',
                        permissionKey: 'GetExternalPaymentBalance',
                    },
                ]
            },
        ]
    }
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
