export const environment = {
    production: false,
    name: 'dev',
    base_route: 'https://dev.api.katapulk.com',
    //base_route: 'https://staging.api.katapulk.com',
    api_service: 'https://devapi-services.katapulk.com',
    //api_service: 'https://stgapi-services.katapulk.com',
    X_Payment_Client: '63e53be11c720997f9d19012',
    v1: '/api/v1',
    v2: '/api/v2',

    token_resource: '/spree_oauth/token',
    refresh_token_resource: '/spree_oauth/token',
    recaptcha: {
        siteKey: '6LeZN4YhAAAAAIusZxPjoJlrvuvKivJMUxosGSpC'
    },
    promotion_images_url: "https://katapulk-banners.s3.amazonaws.com/banners.json",
    promotion_images_url_country_iso: "https://katapulk-banners-dev.s3.amazonaws.com/{country_iso}/banners.json",
    cart_time: 60,
    default_store: 1,
    default_store_code:'mercado-unico',
    fingerprint_endpoint: 'https://fku.staging.katapulk.com',
    fingerprint_api_key: 'Ldn2fEkae4kqrrgFKXK6',
    sentry_dsn: 'https://09275ff967d349cd95ffc8799b60f3b2@o4504119300063232.ingest.sentry.io/4504119794270208',
    isLocal: true,
    useLookUp: false,
    cacheExpirationTime: 30, // minutes
    auth_resource: 'https://dev.auth.katapulk.com/sign-in',
    personal_information_resource: 'https://dev.auth.katapulk.com/personal-information',
    pusher_app_id: '14f4c1d6d48de4aadb00'
};
