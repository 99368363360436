import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {catchError, Observable, of, switchMap, throwError, BehaviorSubject, tap} from 'rxjs';
import { UserService } from 'app/core/user/user.service';
import { environment} from "../../environments/environment";
import { map } from "rxjs/operators";
import * as moment from "moment";
import {User} from "../user/user.types";
import {Router} from "@angular/router";

export interface Token {
    access_token?: string;
    refresh_token?: string;
    created_at?: number;
    expires_in?: number;
}

export const KeyStore = {
    CURRENT_USER: 'current_user',
    IS_AUTH: 'is_auth',
    ACCESS_TOKEN: 'access_token',
    REFRESH_TOKEN: 'refresh_token',
    ACCESS_TOKEN_OBJECT: 'auth',
    TOKEN_EXPIRATION_DATE: 'token_expiration_date',
    PERMISSIONS_PANEL: 'permissionsPanel',
    SCOPE_DEFINITIONS_PANEL: 'ScopeDefinitionsPanel'
};

export interface PermissionsPanel {
    accessResource: string;        // "Settings"
    accessResourceAction: string;  // "Read"
    canAccess: boolean;            // false
    endpoint: string;              // "/api/v1/management/Settings"
    httpMethod: string;            // "GET"
    operationName: string;         // "GetSettingsQuery"
}

const ENUM_ACCESS = {
    PANEL_ACCESS: 'PanelAccess',
    DASHBOARD_ACCESS: 'DashboardsAccess',
    ADMIN: 'admin',
};
const DASHBOARD_TYPE_ENUM = ['DashboardsAccess-Dashboard'];
const ENUM_EMAIL_STATUS = {
    0 : "None",
    1 : "Unconfirmed",
    2 : "Confirmed"
}

const ENUM_PHONE_STATUS = {
    0 : "Unconfirmed",
    1 : "Confirmed"
}

const ENUM_WALLET_TYPE = {
    1: "Default",
    11: "MLC",
    12: "CUP"
}

@Injectable()
export class AuthService
{
    private _authenticated: boolean = false;
    public currentUserSubject: BehaviorSubject<User> = new BehaviorSubject(null);
    public currentDecodeSubject: BehaviorSubject<any> = new BehaviorSubject(null);
    public permissionsPanel = new BehaviorSubject<PermissionsPanel[]>(null);
    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService,
        private _router: Router
    ) {}

    set keyAccessToken(token: string)
    {
        localStorage.setItem(KeyStore.ACCESS_TOKEN, JSON.stringify(token));
    }

    get keyAccessToken(): string
    {
        const json = localStorage.getItem(KeyStore.ACCESS_TOKEN) ?? '';
        return json != '' ? JSON.parse(json) : '';
    }

    set keyRefreshToken(token: string)
    {
        localStorage.setItem(KeyStore.REFRESH_TOKEN, JSON.stringify(token));
    }

    get keyRefreshToken(): string
    {
        const json = localStorage.getItem(KeyStore.REFRESH_TOKEN) ?? '';
        return json != '' ? JSON.parse(json) : '';
    }

    set keyCurrentUser(user: User)
    {
        localStorage.setItem(KeyStore.CURRENT_USER, JSON.stringify(user));
    }

    get keyCurrentUser(): User
    {
        const json = localStorage.getItem(KeyStore.CURRENT_USER) ?? '';
        return json != '' ? JSON.parse(json) : '';
    }

    set keyAccessTokenObject(obj: any)
    {
        localStorage.setItem(KeyStore.ACCESS_TOKEN_OBJECT, JSON.stringify(obj));
    }
    get emailStatusTypes(): string[] {
        return Object.entries(ENUM_EMAIL_STATUS).map(([_, value]) => value);
    }

    get phoneStatusTypes(): any[] {
        return Object.entries(ENUM_PHONE_STATUS).map(([_, value]) => value);
    }

    get keyAccessTokenObject(): string
    {
        const json = localStorage.getItem(KeyStore.ACCESS_TOKEN_OBJECT) ?? '';
        return json != '' ? JSON.parse(json) : '';
    }

    set keyTokenExpirationDate(date: string)
    {
        localStorage.setItem(KeyStore.TOKEN_EXPIRATION_DATE, JSON.stringify(date));
    }

    get keyTokenExpirationDate(): string
    {
        const json = localStorage.getItem(KeyStore.TOKEN_EXPIRATION_DATE) ?? '';
        return json != '' ? JSON.parse(json) : '';
    }

    set keyPermissionsPanel(permissions: PermissionsPanel[])
    {
        localStorage.setItem(KeyStore.PERMISSIONS_PANEL, JSON.stringify(permissions));
    }

    get keyPermissionsPanel(): PermissionsPanel[]
    {
        const json = localStorage.getItem(KeyStore.PERMISSIONS_PANEL) ?? '';
        return json != '' ? JSON.parse(json) : [];
    }

    set keyScopeDefinitionsPanel(permissions: {ScopeId: string, ScopeName: string, Id: string}[])
    {
        localStorage.setItem(KeyStore.SCOPE_DEFINITIONS_PANEL, JSON.stringify(permissions));
    }

    get keyScopeDefinitionsPanel(): {ScopeId: string, ScopeName: string, Id: string}[]
    {
        const json = localStorage.getItem(KeyStore.SCOPE_DEFINITIONS_PANEL) ?? '';
        return json != '' ? JSON.parse(json) : [];
    }

    // public getTokenExpirationDate(): string {
    //     return <string>this.cookieService.get(KeyStore.TOKEN_EXPIRATION_DATE);
    // }

    // public isAuthenticated(): boolean {
    //     return this.cookieService.get(KeyStore.IS_AUTH) === 'true';
    // }

    isTokenExpired() {
        const tokenExpirationDate = this.keyTokenExpirationDate;
        return tokenExpirationDate && moment(new Date()).diff(new Date(tokenExpirationDate)) >= 0;
    }

    // forgotPassword(email: string): Observable<any>
    // {
    //     return this._httpClient.post('api/auth/forgot-password', email);
    // }
    //
    // resetPassword(password: string): Observable<any>
    // {
    //     return this._httpClient.post('api/auth/reset-password', password);
    // }

    hasPermission(permissionKey: string) {
        let hasPermission = false;
        const permissionsPanel: PermissionsPanel[] = this.keyPermissionsPanel;

        if (permissionsPanel && permissionsPanel.length) {
            const isHavePermission = permissionsPanel.some((it) =>
                    it.operationName.toLowerCase() === permissionKey.toLowerCase() && it.canAccess
            );
            if (isHavePermission) {
                hasPermission = true;
            }
        }

        return hasPermission;
    }

    signIn(loginData: { username: string, password: string, grant_type: string }): Observable<any>{
        // Throw error, if the user is already logged in
        // if ( this._authenticated )
        // {
        //     return throwError('User is already logged in.');
        // }
        return this.getAccessToken(loginData)
            .pipe(
                map((token: Token) => {
                    this._authenticated = true;
                    return of(true);
                }),
                catchError((error: any) => {
                    // this.blockUI.stop();
                    return of(error);
                })
            );
    }

    public getAccessToken(loginData: { username: string, password: string, grant_type: string }): Observable<any> {
        const headers = new HttpHeaders()
            .set('Access-Control-Allow-Origin', '*')
            .set('Content-Type', 'application/json; charset=utf-8')
            .set('Accept', 'application/json');

        const url = environment.base_route + environment.token_resource;
        return this.getToken(url, loginData, headers);
    }

    private getToken(url: string, data: any, headers: HttpHeaders): Observable<boolean> {
        return this._httpClient.post(url, data, {headers})
            .pipe(
                switchMap((resp: any) => {
                    const token = {
                        access_token: resp?.data?.tokens?.accessToken,
                        refresh_token: resp?.data?.tokens?.refreshToken,
                    }
                    this.saveToken(token);
                    return of(true);
                }),
                catchError(err => {
                    this.logout();
                    this._router.navigate(['/sign-in']);
                    return of(err);
                })
            );
    }

    private saveToken(token: Token): void {
        // this.cookieService.set(KeyStore.IS_AUTH, 'true');
        // this.currentTokenSubject.next(token);
        // this.cookieService.set(KeyStore.ACCESS_TOKEN_OBJECT, JSON.stringify(token));
        this._authenticated = true;
        this.keyAccessTokenObject = token;

        if (token.access_token) {
            // this.cookieService.set(KeyStore.ACCESS_TOKEN, token.access_token);
            this.keyAccessToken = token.access_token;
        }
        if (token.refresh_token) {
            // this.cookieService.set(KeyStore.REFRESH_TOKEN, token.refresh_token);
            this.keyRefreshToken = token.refresh_token;
        }
        if (token.expires_in) {
            // this.cookieService.set(KeyStore.TOKEN_EXPIRATION_DATE, moment(new Date()).add(token.expires_in, 'seconds').toISOString());
            this.keyTokenExpirationDate = moment(new Date()).add(token.expires_in, 'seconds').toISOString();
        }
    }

    getReportPermissions() {
        const url = environment?.api_service + environment?.v1 + '/management/Reports/EmbeddedReports/Me';
        return this._httpClient.get(url);
    }

    getPermissions() {
        const url = environment?.api_service + environment?.v1 + '/identity/account/me';
        const httpParams = new HttpParams()
            .set('IncludeAccessAndPermissions', true);
        return this._httpClient.get(url, {params: httpParams})
            .pipe(
                switchMap((response: any) => {
                    const user = {
                        id: response.data.userId,
                        email: response.data.email,
                        name: response.data.email,
                        avatar: response.data.avatar ?? 'assets/images/avatars/profile.jpg'
                    };
                    this._userService.user = user;
                    this.keyCurrentUser = user;
                    if (response.data && response.data.userEndpointAccess && response.data.userEndpointAccess.length) {
                        if (response.data.accessPolicies && response.data.accessPolicies.length &&
                            response.data.accessPolicies.some(it => it.resource == ENUM_ACCESS.PANEL_ACCESS)) {
                            const permissionPanelArray = response.data.userEndpointAccess;
                            const dashboardsAccess = response.data.accessPolicies.filter(it => it.resource == ENUM_ACCESS.DASHBOARD_ACCESS);
                            const isAdmin = response.data.roles.some(it => it == ENUM_ACCESS.ADMIN);
                            if (isAdmin) {
                                DASHBOARD_TYPE_ENUM.forEach(it => {
                                    permissionPanelArray.splice(0, 0, {
                                        accessResource: ENUM_ACCESS.DASHBOARD_ACCESS,
                                        accessResourceAction: '',
                                        canAccess: true,
                                        endpoint: '',
                                        httpMethod: '',
                                        operationName: it
                                    });
                                });
                            } else if (dashboardsAccess.length) {
                                dashboardsAccess.forEach(it => {
                                    permissionPanelArray.splice(0, 0, {
                                        accessResource: ENUM_ACCESS.DASHBOARD_ACCESS,
                                        accessResourceAction: '',
                                        canAccess: true,
                                        endpoint: '',
                                        httpMethod: '',
                                        operationName: it.resource + '-' + it.resourceScopes
                                    });
                                });
                            }
                            this.permissionsPanel.next(permissionPanelArray);
                            this.keyPermissionsPanel = permissionPanelArray;
                            return of(response);
                        } else {
                            response = new HttpErrorResponse({
                                error     : 'AUTH.USER_WITHOUT_ACCESS_TO_THE_PANEL',
                                status    : 404,
                                statusText: 'NOT FOUND'
                            });

                            return throwError(response);
                        }
                    } else {
                        // return of(response);
                        throw throwError(response);
                    }
                })
            );
    }

    // public getCurrentRefreshToken(): string {
    //     return <string>this.cookieService.get(KeyStore.REFRESH_TOKEN);
    // }

    public refreshToken(): Observable<boolean> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'accept': 'application/json',
        });

        const data = { accessToken: this.keyAccessToken, refreshToken: this.keyRefreshToken };

        const url = environment.api_service + environment.v1 + '/management/identity/account/auth/refresh';

        return this.getToken(url, data, headers);
    }

    logout() {
        this._authenticated = false;

        localStorage.removeItem(KeyStore.IS_AUTH);
        localStorage.removeItem(KeyStore.ACCESS_TOKEN);
        localStorage.removeItem(KeyStore.REFRESH_TOKEN);
        localStorage.removeItem(KeyStore.CURRENT_USER);
        localStorage.removeItem(KeyStore.ACCESS_TOKEN_OBJECT);
        localStorage.removeItem(KeyStore.TOKEN_EXPIRATION_DATE);
        localStorage.removeItem(KeyStore.PERMISSIONS_PANEL);
        localStorage.removeItem(KeyStore.SCOPE_DEFINITIONS_PANEL);
        this.currentUserSubject.next(null);
        this.currentDecodeSubject.next(null);
    }

    async signOff() {
        const url = environment?.api_service + environment?.v1 + '/management/identity/account/auth/signoff';
        await this._httpClient.post(url, {refreshToken: this.keyRefreshToken}).toPromise()
            .then((response) => {
                console.log('SignOff:', response);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    // signInUsingToken(): Observable<any>
    // {
    //     // Sign in using the token
    //     return this._httpClient.post('api/auth/sign-in-with-token', {
    //         accessToken: this.accessToken
    //     }).pipe(
    //         catchError(() =>
    //             of(false)
    //         ),
    //         switchMap((response: any) => {
    //             if ( response.accessToken )
    //             {
    //                 this.accessToken = response.accessToken;
    //             }
    //
    //             // Set the authenticated flag to true
    //             this._authenticated = true;
    //
    //             // Store the user on the user service
    //             this._userService.user = response.user;
    //
    //             // Return true
    //             return of(true);
    //         })
    //     );
    // }

    signOut(): Observable<any>
    {
        this.logout();

        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: { name: string; email: string; password: string; company: string }): Observable<any>
    {
        return this._httpClient.post('api/auth/sign-up', user);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any>
    {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean>
    {
        // Check if the user is logged in
        if ( this._authenticated )
        {
            return of(true);
        }

        // Check the access token availability
        if ( !this.keyAccessToken )
        {
            return of(false);
        }

        // Check the access token expire date
        // if ( AuthUtils.isTokenExpired(this.keyAccessToken) )
        // {
        //     return of(false);
        // }
        const tokenExpired = this.isTokenExpired();
        if (tokenExpired) {
            return this.refreshToken();
        } else {
            return of(true);
        }

        // If the access token exists and it didn't expire, sign in using it
        // return this.signInUsingToken();

        // const headers = new HttpHeaders()
        //     .set('Access-Control-Allow-Origin', '*')
        //     .set('Content-Type', 'application/json')
        //     .set('Accept', 'application/json');
        // const data = { grant_type: 'refresh_token', refresh_token: this.getCurrentRefreshToken() };
        // const url = environment.base_route + environment.refresh_token_resource;
        // return this._httpClient.post(url, data, {headers})
        //     .pipe(
        //         switchMap((token: Token) => {
        //             this.saveToken(token);
        //             return of(true);
        //         })
        //     );
    }

    getTokensWithCode(code: string ): Observable<any> {
        const url  = environment.api_service + environment.v1 + '/management/identity/account/auth/login/redeemcode';

        const headers = new HttpHeaders({
            'accept': 'application/json',
            'x-k-app': '4'
        });
        return this._httpClient.get(url, { headers: headers , params: {Code: code}}).pipe(
            switchMap( (resp: any ) => {
                this.saveToken({
                    access_token: resp?.data?.tokens?.accessToken,
                    refresh_token: resp?.data?.tokens?.refreshToken,
                });
                this.currentDecodeSubject.next(resp);
                return of(resp);
            }),
            catchError( (err, caught) => {
                this.currentDecodeSubject.next(err);
                return of(err);
            })
        )
    }

    getAuthCode():Observable<any>{
        const url = environment.api_service + environment.v1 + '/identity/account/getaccesscode';

        return this._httpClient.get(url).pipe(
            tap(resp => {
                return of(resp);
            }),
            catchError(err => {
                return of(err);
            })
        );
    }

    getEmailStatus(emailStatus: any): string{
        return ENUM_EMAIL_STATUS[emailStatus];
    }

    getPhoneStatus(phoneStatus: any): string{
        return ENUM_PHONE_STATUS[phoneStatus];
    }

    getWalletType(walletType: any): string{
        return ENUM_WALLET_TYPE[walletType];
    }
}
